<!--DOCK DESKTOP-->

<div *ngIf="dockSize == 'full' || dockSize == 'medium'">
    <!-- стеклянная подложка-->
    <div [class]="'dock-footer ' + dockMode">
        <div class="gradient"></div>
        <div class="desk">
            <nav [class]="'icons-wrapper'">
                <div *ngFor="let icon of iconList; let odd=odd;">
                    <!--док desktop-->
                    <a [class]="'dock ' + mainSize + ((odd)? ' even' : ' odd') "
                         href="{{icon.routerLink}}" target="_self"
                         routerLink="{{icon.routerLink}}" routerLinkActive="selected_route">
                        <div class="icon-item {{icon.class}}"
                             (mousedown)="onPush($event, icon)" (mouseup)="onMouseUp($event, icon)"></div>
                        <div [class]="'text_container ' + mainSize ">
                            <div class="text"><p [innerHTML]="'Dock.'+icon.class | translate"></p></div>
                        </div>
                        <div *ngIf="icon.class != 'home'" class="selected_item"></div>
                    </a>
                </div>
            </nav>
        </div>
    </div>

</div>

<!--DOCK MOBILE-->

<div *ngIf="dockSize == 'small' || dockSize == 'xs' || dockSize == 'xxs'">
    <!--подложка-->
    <div [class]="'dock-mobile ' + ' ' + dockMode + ' ' + dockShow" [style.height]="dockHeight + 'px'"
         [style.bottom]="dockBottom + 'px'">

        <!-- пипка для иконок на доке -->
        <div [class]="'tool dark'"
             *ngIf="dockMode == 'dock' && (dockSize == 'small' || dockSize == 'xs' || dockSize == 'xxs')"
             (click)="onShowDock()">
            <div class="tool-container"></div>
        </div>


        <nav [class]="'icons-wrapper' + ' ' + dockSize">
            <div *ngFor="let icon of iconList; let odd=odd;">
                <!--док mobile-->
                <div [class]="'dockMobile ' + ((odd)? ' even' : ' odd') "
                     routerLink="{{icon.routerLink}}"
                     routerLinkActive="selected_route">
                    <div class="over_item"></div>
                    <div class="icon-item {{icon.class}}"
                         (mousedown)="onPush($event, icon)" (mouseup)="onMouseUp($event, icon)"></div>
                    <div [class]="'text_container '">
                        <div class="text"><p [innerHTML]="'Dock.'+icon.class | translate"></p></div>
                    </div>

                    <div *ngIf="icon.class != 'home'" class="selected_item"></div>

                </div>
            </div>
        </nav>

    </div>

</div>









