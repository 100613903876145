import {InformationSecurityComponent} from "../information-security/information-security.component";

export const sectionsMetadata = {
    start: {
        meta: <IMetadata> {
            title: 'Alaris Labs - Telecom Software Products',
            description: 'Alaris Labs is a software developing company that provides a suite of products enabling voice wholesale and SMS hubbing business. Voice carriers can benefit from Alaris inVoice - a high-capacity billing and routing solution that facilitates partner management (interconnections, rates, billing, bilateral agreements, DID management, alerting, analytics) and ensures real-time routing with a manual or dynamic (LCR, quality-based etc.) approach on a number of softswitches and SBCs (Sonus, Sansay, Dialogic, Genband, Mera MVTS, Huawei, VOS, Freeswitch and others) over the SIP 300/302 API. Alaris Messaging Toolkit is a set of products helping mobile carriers and aggregators efficiently run messaging business. SMS aggregation can be most efficiently organized with Alaris SMS Platform - an all-in-one system to handle one- or two-way A2P and P2P traffic via a multitude of protocols and OTT APIs: SMPP, CMPP, HTTP, SIGTRAN, UCP, ParlayX, RCS, WhatsApp, Viber, Telegram etc. Heavy-duty message switching (up to 25000 SMS/sec) is supplemented with a vast variety of business features: efficient carrier and rate management, real-time routing, event- and session-based billing, volume and swap deals, reporting and analysis). Enterprise messaging is powered by Alaris Campaign Portal - an omnichannel solution enabling B2C A2P messaging for retail brands. The highest degree of mobile network protection is achieved by means of Alaris SMS Firewall that filters all MNO traffic over SS7 and SMPP based on intelligent rules analyzing message content, Sender ID, source GT, traffic volume patterns in order to detect SIM boxes, grey routes and other types of fraud.',
            keywords: 'alaris, alarislabs, alaris labs, Alaris Messaging Toolkit, Alaris inVoice, Alaris SMS Platform, a2p hubbing, sms firewall, mnp server,campaign portal, billing, LRC, invoice, SIP API, vendor routes, dialer traffic, a2p sms platform, smpp platform, rcs platform, sms hubbing platform, sms retail platform, sms enterprise platform, voice wholesale, SMS hubbing business, Voice carriers, inVoice, billing solution, routing solution, interconnections, rates, billing, bilateral agreements, DID management, alerting, analytics, real-time routing, LCR, softswitch, Sonus, Sansay, Dialogic, Genband, Mera MVTS, Huawei, VOS, Freeswitch,  SIP 300/302 API, mobile carrier, messaging business, mobile aggregator, SMS aggregation ,  OTT APIs: SMPP, CMPP, HTTP, SIGTRAN, UCP, ParlayX, RCS, WhatsApp, Viber, Telegram, message switching , rate management, efficient carrier, real-time routing, session-based billing, event-based billing, swap deals, volume deals, reporting, analysis, Enterprise messaging, omnichannel solution, A2P messaging, mobile network protection deals, MNO traffic, SS7, SMPP, intelligent rules, Sender ID, source GT,traffic volume patterns, SIM boxes, grey routes'
        }
    },

    about:{
        meta: <IMetadata>{
            title: 'Alaris Labs - About Us',
            description: 'We give your telecom business wings to fly',
            keywords: 'alaris, alarislabs, alaris labs, about, company'
        }
    },

    messaging:{
        meta: <IMetadata>{
            title: 'Alaris Labs - Messaging Solutions',
            description: 'Complete set of tools for SMS business management',
            keywords: 'alaris, alarislabs, alaris labs, messaging'
        }
    },

    voice:{
        meta: <IMetadata>{
            title: 'Alaris Labs - Voice Solutions',
            description: 'Your ultimate solution for Voice business management',
            keywords: 'alarislabs, voice'
        }
    },

    demo:{
        meta: <IMetadata>{
            title: 'Alaris Labs - DEMO',
            description: 'Alaris Labs - DEMO',
            keywords: 'alarislabs, demo'
        }
    },

    expo:{
        meta: <IMetadata>{
            title: 'Alaris Labs - Exhibitions',
            description: 'Learn about upcoming events',
            keywords: 'alarislabs, Exhibitions'
        }
    },

    contacts:{
        meta: <IMetadata>{
            title: 'Alaris Labs - Contact Us',
            description: 'Get in touch with our Sales',
            keywords: 'alarislabs, contacts'
        }
    },

    social:{
        meta: <IMetadata>{
            title: 'Alaris Labs - Social Media',
            description: 'Get an insight into Alaris life',
            keywords: 'alarislabs, social'
        }
    },

    news:{
        meta: <IMetadata>{
            title: 'Alaris Labs - News',
            description: 'Get an insight into Alaris life',
            keywords: 'alarislabs, news, blog'
        }
    },

    blog:{
        meta: <IMetadata>{
            title: 'Alaris Labs - Blog',
            description: 'Get an insight into Alaris life',
            keywords: 'alarislabs, blog, news'
        }
    },

    faq:{
        meta: <IMetadata>{
            title: 'Alaris Labs - FAQ',
            description: 'Have a question about Alaris Software?',
            keywords: 'alarislabs, faq'
        }
    },

    clients:{
        meta: <IMetadata>{
            title: 'Alaris Labs - Our Clients',
            description: 'Check out our Clients\' testimonials',
            keywords: 'alarislabs, clients'
        }
    },

    smsCalculator:{
        meta: <IMetadata>{
            title: 'Alaris Labs - SMS Calculator',
            description: 'A free tool to calculate message length',
            keywords: ''
        }
    },

    privacyNotice:{
        meta: <IMetadata>{
            title: 'Alaris Labs - Privacy Notice',
            description: 'Learn how we manage personal data',
            keywords: ''
        }
    },

    informationSecurity:{
        meta: <IMetadata>{
            title: 'Alaris Labs - Information Security',
            description: 'Learn how we manage personal data',
            keywords: ''
        }
    }

}

export interface IMetadata {
    title: string;
    description: string;
    keywords: string;
}

