<!-- Document Wrapper
============================================= -->
<div id=" wrapper" class="stretched clearfix" style="opacity: 1;">

  <header>
    <div class="logo" routerLink="/">
      <div class="header-details"> | Customer Satisfaction Survey (2019)</div>
    </div>
  </header>


  <section class="customer-survey-wrapper">
    <div class="customer-survey-container">
      <iframe #iframe height="1600" width="750"></iframe>
      <!--<a href="http://webanketa.com/forms/68vkjchj6rqp8d9pcgv3ed31/" target="_blank">Customer Satisfaction Survey (2019)</a>-->
      <!--<script src="http://webanketa.com/direct/js/embed.js" async>-->
        <!--var WebAnketaParams = ["68vkjchj6gqp4d1gc8r6crb6", "Изучение мнения клиентов Alaris Labs (2019 г.)"];-->
      <!--</script>-->
    </div>

  </section>

</div>




