<div class="lang-container">
  <div class="lang active" [ngClass]="currentLang"></div>


  <div class="lang-selector">
    <div class="lang-selector-item" (click)="switchLanguage('en')">English</div>
    <div class="lang-selector-item" (click)="switchLanguage('ru')">Русский</div>
    <div class="lang-selector-item" (click)="switchLanguage('sp')">Испанский</div>
  </div>
</div>
