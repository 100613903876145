import {EventEmitter} from "@angular/core";

export class SmsCalculatorService {

    constructor() {
        this.smsCounter = <ISMSCounter>{
            encoding: 'GSM_7BIT',
            encodingType: this.encodeType.GSM_7BIT,
            length: 0,
            per_message: this.messageLength.GSM_7BIT,
            remaining: 0,
            messages: 1,
            content: [],
            smsMessages: []
        }

        this.emitSmsCounterChangeEvent(this.smsCounter);

    }

    smsCounterChange: EventEmitter<ISMSCounter> = new EventEmitter();

    public emitSmsCounterChangeEvent(smsCounter: ISMSCounter) {
        this.smsCounterChange.emit(smsCounter);
    }

    public getSmsCounterChangeEmitter() {
        return this.smsCounterChange;
    }

    public getSmsCounter(): ISMSCounter {
        return this.smsCounter;
    }

    public setSmsText(text: string) {
        this.smsContent = text;
        this.smsCounter.length = this.smsContent.length;
        this.countSmsCalculate(text);

        this.emitSmsCounterChangeEvent(this.smsCounter);
    }


    // CALCULATE CONTROLLER

    public smsCounter: ISMSCounter;
    public smsContent;

    public gsm7bitChars = "@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà";
    public gsm7bitExChar = "\\^{}\\\\\\[~\\]|€";

    public GSM_7BIT = 'GSM_7BIT';
    public GSM_7BIT_EX = 'GSM_7BIT_EX';
    public UTF16 = 'UTF16';

    public gsm7bitRegExp = RegExp("^[" + this.gsm7bitChars + "]*$");
    public gsm7bitExRegExp = RegExp("^[" + this.gsm7bitChars + this.gsm7bitExChar + "]*$");
    public gsm7bitExOnlyRegExp = RegExp("^[\\" + this.gsm7bitExChar + "]*$");

    public messageLength = {
        GSM_7BIT: 160,
        GSM_7BIT_EX: 160,
        UTF16: 70
    };

    public multiMessageLength = {
        GSM_7BIT: 153,
        GSM_7BIT_EX: 153,
        UTF16: 67
    };

    public encodeType = {
        GSM_7BIT: '7 bit',
        GSM_7BIT_EX: '7 bit',
        UTF16: 'Unicode',
    }


    detectEncoding(text) {
        switch (false) {
            case text.match(this.gsm7bitRegExp) == null:
                return this.GSM_7BIT;
            case text.match(this.gsm7bitExRegExp) == null:
                return this.GSM_7BIT_EX;
            default:
                return this.UTF16;
        }
    };

    countGsm7bitEx(text) {
        var char2, chars;
        chars = (function () {
            var _i, _len, _results;
            _results = [];
            for (_i = 0, _len = text.length; _i < _len; _i++) {
                char2 = text[_i];
                if (char2.match(this.gsm7bitExOnlyRegExp) != null) {
                    _results.push(char2);
                }
            }
            return _results;
        }).call(this);
        return chars.length;
    };

    countSmsCalculate(text){

        let encoding, per_message, messages, remaining, content, smsMessages;
        encoding = this.detectEncoding(text);

        let length = text.length;
        if (encoding === this.GSM_7BIT_EX) {
            length += this.countGsm7bitEx(text);
        }
        per_message = this.messageLength[encoding];
        if (length > per_message) {
            per_message = this.multiMessageLength[encoding];
        }
        messages = Math.ceil(length / per_message);
        remaining = (per_message * messages) - length;
        if (remaining == 0 && messages == 0) {
            remaining = per_message;
        }
        content = [];
        smsMessages = [];

        let countWork = [];

        for ( let i = 0, len = text.length; i < len; i++) {
            if(text[i] == "|" || text[i] == "^" || text[i] == "€" || text[i] == "{" || text[i] == "}" || text[i] == "[" || text[i] == "]" || text[i] == "~"){
                countWork.push('ESC');
            }
            countWork.push(text[i]);
        }


        if(messages == 1){
            content[0] = [];
            smsMessages[0] = '';
            for ( let i = 0, len = countWork.length; i < len; i++) {
                content[0].push(countWork[i]);
                smsMessages[0] += String(countWork[i]);
            }
        }else{
            for (let j = 0; j < messages; j++){
                content[j] = [];
                smsMessages[j] = '';
                let len = (j == messages-1) ? length - (per_message * (messages-1)) : per_message;
                for ( let i = 0; i < len; i++) {
                    content[j].push(countWork[j * per_message + i]);
                    if(countWork[j * per_message + i] != 'ESC'){
                        smsMessages[j] += String(countWork[j * per_message + i]);
                    }

                }
            }
        }

        this.smsCounter =  <ISMSCounter>{
            encoding: encoding,
            encodingType: this.encodeType[encoding],
            length: length,
            per_message: per_message,
            remaining: remaining,
            messages: messages,
            content: content,
            smsMessages: smsMessages
        };

    }

}

// INTERFACE

export interface ISMSCounter {
    encoding: string,
    encodingType: string,
    length: number,
    per_message: number,
    remaining: number,
    messages: number,
    content:  [],
    smsMessages: []
}
