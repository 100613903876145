/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dock.component.less.shim.ngstyle";
import * as i1 from "./dock.icons.less.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./dock.component";
import * as i7 from "../services/icons.service";
import * as i8 from "../services/state.service";
var styles_DockComponent = [i0.styles, i1.styles];
var RenderType_DockComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_DockComponent, data: { "animation": [{ type: 7, name: "bubble", definitions: [{ type: 0, name: "init", styles: { type: 6, styles: { opacity: "0" }, offset: null }, options: undefined }, { type: 0, name: "lens", styles: { type: 6, styles: { opacity: "1" }, offset: null }, options: undefined }, { type: 0, name: "bubble", styles: { type: 6, styles: { opacity: "1" }, offset: null }, options: undefined }, { type: 0, name: "dock", styles: { type: 6, styles: { opacity: "1" }, offset: null }, options: undefined }, { type: 1, expr: "init => lens", animation: [{ type: 4, styles: null, timings: "2s ease-out" }], options: null }, { type: 1, expr: "init => bubble", animation: [{ type: 4, styles: null, timings: "2s ease-out" }], options: null }, { type: 1, expr: "lens => dock", animation: [{ type: 4, styles: null, timings: "1s ease-out" }], options: null }, { type: 1, expr: "bubble => dock", animation: [{ type: 4, styles: null, timings: "2s ease-out" }], options: null }], options: {} }] } });
export { RenderType_DockComponent as RenderType_DockComponent };
function View_DockComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 0, "div", [["class", "selected_item"]], null, null, null, null, null))], null, null); }
function View_DockComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 11, "a", [["routerLinkActive", "selected_route"], ["target", "_self"]], [[8, "className", 0], [8, "href", 4], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i2.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵdid(2, 671744, [[2, 4]], 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), i2.ɵdid(3, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i2.ElementRef, i2.Renderer2, i2.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i2.ɵqud(603979776, 1, { links: 1 }), i2.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i2.ɵeld(6, 0, null, null, 0, "div", [], [[8, "className", 0]], [[null, "mousedown"], [null, "mouseup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.onPush($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseup" === en)) {
        var pd_1 = (_co.onMouseUp($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 2, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i2.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_DockComponent_3)), i2.ɵdid(12, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = "_self"; var currVal_5 = i2.ɵinlineInterpolate(1, "", _v.context.$implicit.routerLink, ""); _ck(_v, 2, 0, currVal_4, currVal_5); var currVal_6 = "selected_route"; _ck(_v, 3, 0, currVal_6); var currVal_10 = (_v.context.$implicit.class != "home"); _ck(_v, 12, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("dock " + _co.mainSize) + (_v.context.odd ? " even" : " odd")); var currVal_1 = i2.ɵinlineInterpolate(1, "", _v.context.$implicit.routerLink, ""); var currVal_2 = i2.ɵnov(_v, 2).target; var currVal_3 = i2.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_7 = i2.ɵinlineInterpolate(1, "icon-item ", _v.context.$implicit.class, ""); _ck(_v, 6, 0, currVal_7); var currVal_8 = ("text_container " + _co.mainSize); _ck(_v, 7, 0, currVal_8); var currVal_9 = i2.ɵunv(_v, 9, 0, i2.ɵnov(_v, 10).transform(("Dock." + _v.context.$implicit.class))); _ck(_v, 9, 0, currVal_9); }); }
function View_DockComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 0, "div", [["class", "gradient"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 3, "div", [["class", "desk"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 2, "nav", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_DockComponent_2)), i2.ɵdid(6, 278528, null, 0, i4.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.iconList; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("dock-footer " + _co.dockMode); _ck(_v, 1, 0, currVal_0); var currVal_1 = "icons-wrapper"; _ck(_v, 4, 0, currVal_1); }); }
function View_DockComponent_5(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "div", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowDock() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 0, "div", [["class", "tool-container"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = "tool dark"; _ck(_v, 0, 0, currVal_0); }); }
function View_DockComponent_7(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 0, "div", [["class", "selected_item"]], null, null, null, null, null))], null, null); }
function View_DockComponent_6(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 12, "div", [["routerLinkActive", "selected_route"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i2.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵdid(2, 16384, [[3, 4]], 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i2.Renderer2, i2.ElementRef], { routerLink: [0, "routerLink"] }, null), i2.ɵdid(3, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i2.ElementRef, i2.Renderer2, i2.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i2.ɵqud(603979776, 3, { links: 1 }), i2.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i2.ɵeld(6, 0, null, null, 0, "div", [["class", "over_item"]], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 0, "div", [], [[8, "className", 0]], [[null, "mousedown"], [null, "mouseup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.onPush($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseup" === en)) {
        var pd_1 = (_co.onMouseUp($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 2, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i2.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_DockComponent_7)), i2.ɵdid(13, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = i2.ɵinlineInterpolate(1, "", _v.context.$implicit.routerLink, ""); _ck(_v, 2, 0, currVal_1); var currVal_2 = "selected_route"; _ck(_v, 3, 0, currVal_2); var currVal_6 = (_v.context.$implicit.class != "home"); _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = ("dockMobile " + (_v.context.odd ? " even" : " odd")); _ck(_v, 1, 0, currVal_0); var currVal_3 = i2.ɵinlineInterpolate(1, "icon-item ", _v.context.$implicit.class, ""); _ck(_v, 7, 0, currVal_3); var currVal_4 = "text_container "; _ck(_v, 8, 0, currVal_4); var currVal_5 = i2.ɵunv(_v, 10, 0, i2.ɵnov(_v, 11).transform(("Dock." + _v.context.$implicit.class))); _ck(_v, 10, 0, currVal_5); }); }
function View_DockComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 5, "div", [], [[8, "className", 0], [4, "height", null], [4, "bottom", null]], null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_DockComponent_5)), i2.ɵdid(3, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(4, 0, null, null, 2, "nav", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_DockComponent_6)), i2.ɵdid(6, 278528, null, 0, i4.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = ((_co.dockMode == "dock") && (((_co.dockSize == "small") || (_co.dockSize == "xs")) || (_co.dockSize == "xxs"))); _ck(_v, 3, 0, currVal_3); var currVal_5 = _co.iconList; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((("dock-mobile " + " ") + _co.dockMode) + " ") + _co.dockShow); var currVal_1 = (_co.dockHeight + "px"); var currVal_2 = (_co.dockBottom + "px"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = (("icons-wrapper" + " ") + _co.dockSize); _ck(_v, 4, 0, currVal_4); }); }
export function View_DockComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_DockComponent_1)), i2.ɵdid(1, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_DockComponent_4)), i2.ɵdid(3, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.dockSize == "full") || (_co.dockSize == "medium")); _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_co.dockSize == "small") || (_co.dockSize == "xs")) || (_co.dockSize == "xxs")); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_DockComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "dock", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i2.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DockComponent_0, RenderType_DockComponent)), i2.ɵdid(1, 4440064, null, 0, i6.DockComponent, [i3.Router, i3.ActivatedRoute, i7.IconsService, i8.StateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DockComponentNgFactory = i2.ɵccf("dock", i6.DockComponent, View_DockComponent_Host_0, {}, {}, []);
export { DockComponentNgFactory as DockComponentNgFactory };
