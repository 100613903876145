<!-- Modal SELECT MODE-->

<div class="" #orderModal id="orderModal" [@Show]="show">

  <!-- Postcontent
============================================= -->

  <div class="message-form-wrapper" [@Show]="show">
    <!--[style.opacity]="(showForm ? 1 : 0)"-->
    <div class="black-bg" (click)="onCloseBtn()" [@Show]="show"></div>
    <!--[style.bottom]="(showForm ? 0 : '1000vh')"-->
    <div class="container nobottommargin message-form-container" >

      <div class="close-btn" (click)="onCloseBtn()"></div>
      <h3 [innerHTML]=title></h3>
      <p>Please provide as much information as possible so we can best qualify your needs and make the more efficient proposition</p>


      <div id="contact-form-result" data-notify-type="success"
           data-notify-msg="<i class=icon-ok-sign></i> Message Sent Successfully!"></div>

      <div class="nobottommargin" id="template-contactform" name="template-contactform">

        <div class="form-process"></div>

        <div [class]="'col_one_third form-component ' + ((notValid && !valid.Name) ? 'not-valid' : '')">
          <label for="template-contactform-name">My name
            <small>*</small>
          </label>
          <input type="text" id="template-contactform-name" name="template-contactform-name" value=""
                 class="sm-form-control required"
                 (ngModelChange)="onChangeModel('Name', $event)"
                 [ngModel]="requestModel.Name"/>
          <span> My company name </span>
        </div>

        <div [class]="'col_one_third form-component ' + ((notValid && !valid.Email) ? 'not-valid' : '')">
          <label for="template-contactform-email">My email
            <small>*</small>
          </label>
          <input type="email" id="template-contactform-email" name="template-contactform-email" value=""
                 class="required email sm-form-control"
                 (ngModelChange)="onChangeModel('Email', $event)"
                 [ngModel]="requestModel.Email"/>
          <span> Enter your email </span>
        </div>

        <div class="col_one_third form-component col_last">
          <label for="template-contactform-phone">My Phone</label>
          <input type="text" id="template-contactform-phone" name="template-contactform-phone" value=""
                 (ngModelChange)="onChangeModel('Phone', $event)"
                 [ngModel]="requestModel.Phone"
                 class="sm-form-control"/>
        </div>

        <div class="clear"></div>

        <div [class]="'col_one_third form-component ' + ((notValid && !valid.Company) ? 'not-valid' : '')">
          <label for="template-contactform-subject2">Company
            <small>*</small>
          </label>
          <input type="text" id="template-contactform-subject2" name="template-contactform-subject" value=""
                 class="required sm-form-control"
                 (ngModelChange)="onChangeModel('Company', $event)"
                 [ngModel]="requestModel.Company"/>
          <span> Enter your company name </span>
        </div>

        <div [class]="'col_one_third form-component ' + ((notValid && !valid.Country) ? 'not-valid' : '')">
          <label for="template-contactform-subject3">Country
            <small>*</small>
          </label>
          <input type="text" id="template-contactform-subject3" name="template-contactform-subject" value=""
                 class="required sm-form-control"
                 (ngModelChange)="onChangeModel('Country', $event)"
                 [ngModel]="requestModel.Country"/>
          <span> Enter your country name </span>
        </div>

        <div class="clear"></div>

        <div *ngIf="productType == 'inVoice'">

        <div [class]="'col_one_third form-component ' + ((notValid && !valid.SwitchBrand) ? 'not-valid' : '')">
          <label for="template-contactform-subject4">My Switch Brand
            <small>*</small>
          </label>
          <input type="text" id="template-contactform-subject4" name="template-contactform-subject" value=""
                 class="required sm-form-control"
                 (ngModelChange)="onChangeModel('SwitchBrand', $event)"
                 [ngModel]="requestModel.SwitchBrand"/>
          <span> Switch Brand</span>
        </div>

        <div [class]="'col_one_third form-component '">
          <label for="template-contactform-subject5">My approximate monthly volume</label>
          <input type="text" id="template-contactform-subject5" name="template-contactform-subject" value=""
                 class="required sm-form-control"
                 (ngModelChange)="onChangeModel('MonthlyVolume', $event)"
                 [ngModel]="requestModel.MonthlyVolume"/>
          <span> Enter your Monthly Volume </span>
        </div>

<!--        <div [class]="'col_one_third form-component col_last'">-->
<!--          <p>We can also include Swap Management-->
<!--            to your Alaris inVoice Billing Solution. </p>-->
<!--          <input type="checkbox" id="template-contactform-subject6" name="template-contactform-subject" value=""-->
<!--                 class="required sm-form-control"-->
<!--                 (ngModelChange)="onChangeModel('Include', $event)"-->
<!--                 [ngModel]="requestModel.Include"/> Great! INCLUDE-->

<!--        </div>-->

          <div [class]="'col_one_third col_last form-component'">
            <p>We can also include Swap Management to your Alaris inVoice Billing Solution. </p>
            <label class="checkbox">
              <input type="checkbox" id="template-contactform-include"
                     name="template-contactform-include" value=""
                     (ngModelChange)="onChangeModel('Include', $event)"
                     [ngModel]="requestModel.Include"/>
              <div class="checkbox__text">Great! INCLUDE</div>
            </label>
<!--            <span>Confirm reading our Privacy Notice</span>-->
          </div>

      </div>
        <div class="clear"></div>

        <div [class]="'col_full form-component ' + ((notValid && !valid.Message) ? 'not-valid' : '')">
          <label for="template-contactform-message">You also need to know that
            <small>*</small>
          </label>
          <textarea class="required sm-form-control" id="template-contactform-message"
                    name="template-contactform-message" rows="6" cols="30"
                    (ngModelChange)="onChangeModel('Message', $event)"
                    [ngModel]="requestModel.Message">
              </textarea>
        </div>

        <div [class]="'col_full form-component ' + ((notValid && !valid.Privacy) ? 'not-valid' : '')">
          <label class="checkbox">
            <input type="checkbox" id="template-contactform-privacy"
                   name="template-contactform-privacy" value=""
                   (ngModelChange)="onChangeModel('Privacy', $event)"
                   [ngModel]="requestModel.Privacy"/>
            <div class="checkbox__text">I have read the <a routerLink='/privacy-notice'>Alarislabs Privacy Notice</a>
              and hereby consent to the processing of my personal data in accordance with the
              terms thereof.</div>
          </label>
          <span> Confirm reading our Privacy Notice</span>
        </div>

        <div class="col_full topmargin">
          <!--<button class="button button-3d nomargin button-black" style="float: right;" id="template-contactform-reset"-->
                  <!--name="template-contactform-reset"-->
                  <!--(click)="onReset()" value="reset">reset-->
          <!--</button>-->
          <button class="button button-3d nomargin" id="template-contactform-submit"
                  name="template-contactform-submit"
                  (click)="onSubmitRequest()" value="submit">I have questions. Call me
          </button>
        </div>

      </div>

    </div><!-- .postcontent end -->
  </div>
</div>
