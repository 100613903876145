/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alert.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./alert.service";
var styles_AlertComponent = [i0.styles];
var RenderType_AlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertComponent, data: { "animation": [{ type: 7, name: "Show", definitions: [{ type: 0, name: "hide", styles: { type: 6, styles: { opacity: "0", top: "-30em" }, offset: null }, options: undefined }, { type: 0, name: "show", styles: { type: 6, styles: { opacity: "1", top: "5em" }, offset: null }, options: undefined }, { type: 1, expr: "hide => show", animation: [{ type: 4, styles: null, timings: "0.3s ease-out" }], options: null }, { type: 1, expr: "show => hide", animation: [{ type: 4, styles: null, timings: "0.3s ease-in" }], options: null }], options: {} }] } });
export { RenderType_AlertComponent as RenderType_AlertComponent };
function View_AlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-gift"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", "!"])), (_l()(), i1.ɵted(4, null, [" ", "."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.state.text; _ck(_v, 4, 0, currVal_1); }); }
function View_AlertComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-hand-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", "!"])), (_l()(), i1.ɵted(4, null, [" ", "."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.state.text; _ck(_v, 4, 0, currVal_1); }); }
function View_AlertComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-warning-sign"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", "!"])), (_l()(), i1.ɵted(4, null, [" ", "."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.state.text; _ck(_v, 4, 0, currVal_1); }); }
function View_AlertComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-remove-sign"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", "!"])), (_l()(), i1.ɵted(4, null, [" ", "."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.state.text; _ck(_v, 4, 0, currVal_1); }); }
export function View_AlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["alert", 1]], null, 11, "div", [["class", "alert-wrapper show"], ["id", ""]], [[24, "@Show", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["aria-hidden", "true"], ["class", "close"], ["data-dismiss", "alert"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseBtn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_4)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.state.type == "success"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.state.type == "info"); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.state.type == "warning"); _ck(_v, 9, 0, currVal_4); var currVal_5 = (_co.state.type == "danger"); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.state; _ck(_v, 0, 0, currVal_0); var currVal_1 = ("alert alert-" + _co.state.type); _ck(_v, 1, 0, currVal_1); }); }
export function View_AlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i1.ɵdid(1, 4440064, null, 0, i3.AlertComponent, [i4.TranslateService, i5.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i1.ɵccf("alert", i3.AlertComponent, View_AlertComponent_Host_0, {}, {}, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
