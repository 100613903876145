import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {OrderService} from "./order.service";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {ContactService} from "../../services/contact.service";
import {AlertService, IAlert} from "../alert/alert.service";

@Component({
    selector: 'order-modal',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.less', '../checkbox.less'],
    animations: [
        trigger("Show", [
            state('hide', style({
                opacity: '0',
                // position: 'absolute',
                top: '-100vh',
            })),
            state('show', style({
                opacity: '1',
                // position: 'absolute',
                top: '0',
            })),
            transition('hide => show', [
                animate('0.3s ease-out')
            ]),
            transition('show => hide', [
                animate('0.3s ease-in')
            ]),
        ])
    ]
})

export class OrderComponent implements OnInit {

    constructor(
        private translate: TranslateService,
        private service: OrderService,
        public router: Router,
        public contactService: ContactService,
        public alert: AlertService,
        public activatedRoute: ActivatedRoute
    ) {

        this.requestModel = this.contactService.getModel();
        this.requestModel.Privacy = false;
        this.reset();
    }

    public showForm: 'show' | 'hide';
    public loading = false;
    public requestModel = <any>{};
    public valid = {
        Name: false,
        Email: false,
        // Phone: false,
        SwitchBrand: false,
        // MonthlyVolume: false,
        Company: false,
        Country: false,
        // Include: false,
        Message: false,
        Privacy: false
    };
    public notValid = false;

    reset() {
        this.valid = {
            Name: false,
            Email: false,
            // Phone: false,
            SwitchBrand: false,
            // MonthlyVolume: false,
            Company: false,
            Country: false,
            // Include: false,
            Message: false,
            Privacy: false
        };
    }

    resetValid() {

        Object.keys(this.requestModel).forEach(key => {
            if (this.requestModel['ProductType']) {
                this.valid[key] = !!this.requestModel[key];
            } else {
                this.valid[key] = (key == 'SwitchBrand') ? false : !!this.requestModel[key];
            }

        })


    }


    onChangeModel(key, val) {
        this.notValid = false;
        this.requestModel[key] = val;
        this.valid[key] = this.isValidByKey(key, val);
        this.contactService.setModel(this.requestModel);
    }


    isValidByKey(key, val) {
        let tst = false;
        let email = /^[\w-\.\d*]+@[\w\d]+(\.\w{2,4})$/;
        let text = /[^]{3,}/;

        switch (key) {
            case 'Email':
                tst = !!val.match(email);
                break;
            case 'Name':
                tst = !!val.match(text);
                break;
            case 'SwitchBrand':
                tst = !!val.match(text);
                break;
            case 'Company':
                tst = !!val.match(text);
                break;
            case 'Country':
                tst = !!val.match(text);
                break;
            case 'Message':
                tst = !!val.match(text);
                break;
            default:
                tst = !!val;
        }

        return tst;
    }

    // -----------------------------------------

    ngAfterViewInit() {
        this.showForm = 'show';
    }

    ngOnInit() {
        this.dataSubscription = this.service.getDataChangeEmitter()
            .subscribe(data => this.changeData(data));

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
                this.onCloseBtn();
                this.resetContactForm();
            }
        });
    }

    resetContactForm() {
        this.contactService.setModel({});
    }

    public odata: any;

    changeData(data: any) {
        this.notValid = false;
        this.odata = data;
        this.show = data.show || 'show';
        this.title = headerText[data.product][data.productType];
        this.productType = data.product;

        this.requestModel['Title'] = this.title;
        this.requestModel['ProductType'] = this.productType;
        this.contactService.setModel(this.requestModel);
    }

    ngOnDestroy() {
        this.dataSubscription.unsubscribe();
    }


    public productType;
    private dataSubscription: any;

    public show: string = 'hide';

    public onCloseBtn() {
        if (this.odata) {
            this.show = 'hide';
            this.odata.show = this.show;
            this.service.setData(this.odata);
        }
    }

    onReset() {
        this.notValid = false;
        this.contactService.setModel({});
    }

    onSubmitRequest() {

        this.notValid = false;

        Object.keys(this.valid).forEach(key => {
            if (this.requestModel['ProductType'] == 'inVoice') {
                this.notValid = this.notValid || !this.valid[key]
            } else {
                this.notValid = this.notValid || !((key == 'SwitchBrand') ? true : this.valid[key]);
            }
        });

        Object.keys(DefaultRequest).forEach(p => {
            this.requestModel[p] = this.requestModel[p] || DefaultRequest[p];
        });

        if (this.notValid) return;

        this.contactService.setModel(this.requestModel);
        this.loading = true;

        this.contactService.MailProductForm().subscribe(
            (res: any[]) => {

                this.loading = false;
                this.alert.setState(<IAlert>{
                    state: "show",
                    type: "success",
                    title: "Your message has been sent",
                    text: "Thank you for your feedback. We will contact with you as soon as possible."
                })

                Object.keys(this.valid).forEach(p => {
                    this.valid[p] = false;
                    // this.requestModel[p] = null;
                })

                this.contactService.setModel({});  // Clear contact form

                // this.show = 'hide';
                this.resetModel();
                this.onCloseBtn();

            },
            (err) => {
                this.loading = false;
                this.alert.setState(<IAlert>{
                    state: "show",
                    type: "danger",
                    title: "Server Error",
                    text: "Some thing went wrong. Please try again or contact as via mail."
                })
                this.onCloseBtn();
            }
        );
    }

    public title = 'TEST ORDER';
    public data;

    public resetModel() {
        Object.keys(this.requestModel).forEach(p => {
            this.requestModel[p] = null;
        })
    }


}

export const headerText = {
    inVoice: {
        allInOne: "I want Alaris Labs inVoice all-in-one",

        billingOnPremises: "I want Alaris Billing ON-PREMISES.",
        billingHosted: "I want Alaris Billing HOSTED",
        billingSpecial: "I’m special HELP ME TO DECIDE",

        lcrSwitchOnPremises: "I have my own switch, I want Alaris LCR ON-PREMISES.",
        lcrNoSwitchOnPremises: "I do not have a switch, I want Alaris LCR ON-PREMISES.",
        lcrHosted: "I do not have a switch, I want Alaris LCR HOSTED.",
        lcrSpecial: "I’m special HELP ME TO DECIDE",

        lcrBillingSwitchOnPremises: "I have my own switch, I want Alaris inVoice ON-PREMISES.",
        lcrBillingNoSwitchOnPremises: "I do not have a switch, I want Alaris inVoice ON-PREMISES.",
        lcrBillingHosted: "I do not have a switch, I want Alaris inVoice HOSTED.",
        lcrBillingSpecial: "I’m special HELP ME TO DECIDE.",

        mnpServer: "I want Alaris MNP Server.",

    },

    messagingKit: {
        allInOne: "I want Labs Messaging Kit all-in-one",

        a2pHubbingOnPremises: "I want Alaris SMS Platform ON-PREMISES.",
        a2pHubbingHosted: "I want Alaris SMS Platform HOSTED",
        a2pHubbingSpecial: "I’m special HELP ME TO DECIDE.",

        mKitOnPremises: "I want Alaris MKit ON-PREMISES.",
        mKitHosted: "I want Alaris MKit HOSTED.",
        mKitSpecial: "I’m special HELP ME TO DECIDE.",

        enterpriseOnPremises: "I want Alaris Campaign Portal ON-PREMISES.",
        enterpriseHosted: "I want Alaris Campaign Portal HOSTED.",
        enterpriseSpecial: "I’m special HELP ME TO DECIDE.",

        smsFirewall: "I want Alaris SMS Firewall.",

        mnpServer: "I want Alaris MNP Server.",
    }
}

export const DefaultRequest = {
    Name: '--',
    Email: '--',
    Phone: null,
    SwitchBrand: '--',
    MonthlyVolume: '--',
    Company: '--',
    Country: '--',
    Include: false,
    Privacy: false,
    Message: '--'
};



