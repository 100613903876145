import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from './config';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ContactService = /** @class */ (function () {
    function ContactService(http) {
        this.http = http;
        this.dataChange = new EventEmitter();
        this.requestModel = {};
    }
    // public path : string = '/exhibitions';
    ContactService.prototype.handleError = function (error) {
        console.log(error);
        // return an observable with a user friendly message
        return throwError('Error! something went wrong.');
    };
    ContactService.prototype.setModel = function (requestModel) {
        this.requestModel = requestModel;
        // this.emitDataChangeEvent(this.requestModel);
        // console.log(this.requestModel)
    };
    ContactService.prototype.getModel = function () {
        return this.requestModel;
    };
    ContactService.prototype.emitDataChangeEvent = function (mode) {
        this.dataChange.emit(mode);
    };
    ContactService.prototype.getDataChangeEmitter = function () {
        return this.dataChange;
    };
    // ---------- Feedback Mail ----------
    ContactService.prototype.generateFeedbackMail = function () {
        this.mailForm = {};
        this.mailForm.email = this.requestModel.Email;
        this.mailForm.from = this.requestModel.Name;
        this.mailForm.title = 'Feedback from site: ' + this.requestModel.Item;
        this.mailForm.text = "" +
            "Name: " + this.requestModel.Name + "\n" +
            "Company: " + this.requestModel.Company + "\n" +
            "Country: " + this.requestModel.Country + "\n" +
            "Phone: " + this.requestModel.Phone + "\n" +
            "Email: " + this.requestModel.Email + "\n" +
            "Text: " + this.requestModel.Message + "\n";
    };
    ContactService.prototype.MailFeedbackForm = function () {
        this.generateFeedbackMail();
        // console.log('MAIL CONTACT FORM ---> ', this.mailForm);
        return this.http.post(config.apiUrl + "/php/inc/mail.php", this.mailForm, { headers: { 'Content-Type': 'application/json' } }).pipe(map(function (res) {
            // console.log('RESPONSE ---> ', res)
            return res;
        }), catchError(this.handleError));
    };
    // ---------- Contact Mail ----------
    ContactService.prototype.generateContactMail = function () {
        this.mailForm = {};
        this.mailForm.email = this.requestModel.Email;
        this.mailForm.from = this.requestModel.Name;
        this.mailForm.title = 'Message from contact form';
        this.mailForm.text = "" +
            "Name: " + this.requestModel.Name + "\n" +
            "Company: " + this.requestModel.Company + "\n" +
            "Category: " + this.requestModel.Category + "\n" +
            "Phone: " + this.requestModel.Phone + "\n" +
            "Email: " + this.requestModel.Email + "\n" +
            "Text: " + this.requestModel.Message + "\n";
    };
    ContactService.prototype.MailContactForm = function () {
        this.generateContactMail();
        return this.http.post(config.apiUrl + "/php/inc/mail.php", this.mailForm, { headers: { 'Content-Type': 'application/json' } }).pipe(map(function (res) {
            // console.log('RESPONSE ---> ', res)
            return res;
        }), catchError(this.handleError));
    };
    // // ---------- SMART Mail ----------
    // generateSmartMail() {
    //     this.mailForm = <any>{};
    //     this.mailForm.email = this.requestModel.Email;
    //     this.mailForm.from = this.requestModel.Name;
    //     this.mailForm.title = 'SMART SELECT';
    //
    //     this.mailForm.text = "" +
    //     "Market: " + this.requestModel.Smart.Market.VoiceWholesale ? 'Voice Wholesale, ' : '' +
    //     this.requestModel.Smart.Market.SMSHubing ? 'SMS Hubing, ' : '' +
    //     this.requestModel.Smart.Market.EnterpriseMessaging ? 'Enterprise Messaging, ' : '' +
    //     this.requestModel.Smart.Market.Custom ? 'I have in mind something else: ' + this.requestModel.Smart.Market.CustomText : '' +
    //     "\n" +
    //     "Premises: " +
    //     this.requestModel.Smart.Premises.SaaS ? 'SaaS, ' : '' +
    //     this.requestModel.Smart.Premises.MyPremises ? 'My Premises, ' : '' +
    //     this.requestModel.Smart.Premises.Custom ? 'I have in mind an exceptional setup: ' + this.requestModel.Smart.Premises.CustomText : '' +
    //     "\n" +
    //     "Payment Structure: " +
    //     this.requestModel.Smart.PaymentStructure.MRC ? 'MRC, ' : '' +
    //     this.requestModel.Smart.PaymentStructure.OneTime ? 'One Time, ' : '' +
    //     this.requestModel.Smart.PaymentStructure.InstalmentPlan ? 'Instalment Plan, ' : '' +
    //     this.requestModel.Smart.PaymentStructure.Custom ? 'My commercial imagination is beyond standard: ' + this.requestModel.Smart.PaymentStructure.CustomText : '' +
    //     "\n" +
    //     "Premises: " +
    //     this.requestModel.Smart.BusinessLevel.HelpMeToStart ? 'Help me to start, ' : '' +
    //     this.requestModel.Smart.BusinessLevel.MyGrowingBusiness ? 'My Growing Business, ' : '' +
    //     this.requestModel.Smart.BusinessLevel.Custom ? 'I have in mind an exceptional setup: ' + this.requestModel.Smart.BusinessLevel.CustomText : '' +
    //     "\n" +
    //
    //     "Name: " + this.requestModel.Name + "\n" +
    //     "Company: " + this.requestModel.Company + "\n" +
    //     "Country: " + this.requestModel.Country + "\n" +
    //     "Phone: " + this.requestModel.Phone + "\n" +
    //     "Email: " + this.requestModel.Email + "\n" +
    //     "Text: " + this.requestModel.Message + "\n";
    //
    // }
    //
    // MailSmartForm(): Observable<any> {
    //     this.generateSmartMail();
    //     return this.http.post(`${config.apiUrl}/php/inc/mail.php`,
    //         this.mailForm,
    //         {headers: {'Content-Type': 'application/json'}}).pipe(
    //         map((res) => {
    //             // console.log('RESPONSE ---> ', res)
    //             return res;
    //         }), catchError(this.handleError));
    // }
    // ---------- Product Mail ----------
    ContactService.prototype.generateProductMail = function () {
        // Company: "asdasd"
        // Country: "asdasd"
        // Email: "asdasd"
        // Message: "asdasd"
        // MonthlyVolume: "asdasd"
        // Name: "asdasd"
        // Phone: "asdasd"
        // ProductType: "inVoice"
        // SwitchBrand: "asdasd"
        // Title: "I have my own switch, I want Alaris inVoice LCR+Billing
        this.mailForm = {};
        this.mailForm.email = this.requestModel.Email;
        this.mailForm.from = this.requestModel.Name;
        this.mailForm.title = this.requestModel.ProductType + ' request: ' + this.requestModel.Title;
        this.mailForm.text = "" +
            "Name: " + this.requestModel.Name + "\n" +
            "Company: " + this.requestModel.Company + "\n" +
            "Country: " + this.requestModel.Country + "\n" +
            "Phone: " + this.requestModel.Phone + "\n" +
            "Email: " + this.requestModel.Email + "\n" +
            "Monthly Volume: " + this.requestModel.MonthlyVolume + "\n" +
            "Switch Brand: " + this.requestModel.SwitchBrand + "\n" +
            "Product Type: " + this.requestModel.ProductType + "\n" +
            // "Request: " + this.requestModel.Request + "\n" +
            "Text: " + this.requestModel.Message + "\n";
    };
    ContactService.prototype.MailProductForm = function () {
        this.generateProductMail();
        // console.log('MAIL PRODUCT FORM ---> ', this.mailForm);
        return this.http.post(config.apiUrl + "/php/inc/mail.php", this.mailForm, { headers: { 'Content-Type': 'application/json' } }).pipe(map(function (res) {
            // console.log('RESPONSE ---> ', res)
            return res;
        }), catchError(this.handleError));
    };
    ContactService.ngInjectableDef = i0.defineInjectable({ factory: function ContactService_Factory() { return new ContactService(i0.inject(i1.HttpClient)); }, token: ContactService, providedIn: "root" });
    return ContactService;
}());
export { ContactService };
