import { EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from './config';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HotService = /** @class */ (function () {
    function HotService(http) {
        this.http = http;
        this.path = '/hot';
        this.dataChange = new EventEmitter();
        this.requestModel = {};
        this.hotList = [];
    }
    HotService.prototype.handleError = function (error) {
        console.log(error);
        // return an observable with a user friendly message
        return throwError('Error! something went wrong.');
    };
    HotService.prototype.getList = function (take, skip) {
        var headers = new HttpHeaders();
        // headers = headers.append('Access-Control-Allow-Origin', '*');
        // return this.http.get(`${config.apiUrl}/news/newsList?take=${take}&skip=${skip}`).pipe(
        return this.http.get("" + config.apiUrl + this.path + "/list.php", { headers: headers }).pipe(map(function (res) {
            return res;
        }), catchError(this.handleError));
    };
    HotService.prototype.checkList = function (take, skip) {
        var headers = new HttpHeaders();
        return this.http.get("" + config.apiUrl + this.path + "/check.php", { headers: headers }).pipe(map(function (res) {
            return res;
        }), catchError(this.handleError));
    };
    HotService.prototype.emitDataChangeEvent = function (data) {
        this.dataChange.emit(data);
    };
    HotService.prototype.getDataChangeEmitter = function () {
        return this.dataChange;
    };
    HotService.prototype.setHotList = function (data) {
        this.hotList = data;
        this.emitDataChangeEvent(this.hotList);
    };
    HotService.prototype.getHotList = function () {
        return this.hotList;
    };
    HotService.ngInjectableDef = i0.defineInjectable({ factory: function HotService_Factory() { return new HotService(i0.inject(i1.HttpClient)); }, token: HotService, providedIn: "root" });
    return HotService;
}());
export { HotService };
