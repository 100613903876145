﻿import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {config} from './config';
import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {IAlert} from "../tools/alert/alert.service";

@Injectable({providedIn: 'root'})
export class HotService {
    constructor(private http: HttpClient) {
        this.requestModel = {};
        this.hotList = [];
    }

    public path: string = '/hot';

    private handleError(error: HttpErrorResponse) {
        console.log(error);
        // return an observable with a user friendly message
        return throwError('Error! something went wrong.');
    }

    getList(take?, skip?) {
        let headers: HttpHeaders = new HttpHeaders();
        // headers = headers.append('Access-Control-Allow-Origin', '*');

        // return this.http.get(`${config.apiUrl}/news/newsList?take=${take}&skip=${skip}`).pipe(
        return this.http.get(`${config.apiUrl}${this.path}/list.php`,{headers}).pipe(
            map((res) => {
                return res;
            }),
            catchError(this.handleError));
    }

    checkList(take?, skip?) {
        let headers: HttpHeaders = new HttpHeaders();
        return this.http.get(`${config.apiUrl}${this.path}/check.php`,{headers}).pipe(
            map((res) => {
                return res;
            }),
            catchError(this.handleError));
    }

    requestModel: any;


    dataChange: EventEmitter<any> = new EventEmitter();

    public emitDataChangeEvent(data: any[]) {
        this.dataChange.emit(data);
    }

    public getDataChangeEmitter() {
        return this.dataChange;
    }

    public setHotList(data: any[]) {
        this.hotList = data;
        this.emitDataChangeEvent(this.hotList);
    }

    public getHotList(): any[] {
        return this.hotList;
    }

    public hotList: any[];

}


