<div class="alert-wrapper show" #alert id="" [@Show]="state.state">

  <div [class]="'alert alert-'+state.type">
    <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="onCloseBtn()">×</button>
    <!--<i class="icon-gift"></i><strong>Well done!</strong> {{state.text}}.-->
    <div *ngIf="state.type == 'success'"><i class="icon-gift"></i><strong>{{state.title}}!</strong> {{state.text}}.</div>
    <div *ngIf="state.type == 'info'"><i class="icon-hand-up"></i><strong>{{state.title}}!</strong> {{state.text}}.</div>
    <div *ngIf="state.type == 'warning'"><i class="icon-warning-sign"></i><strong>{{state.title}}!</strong> {{state.text}}.</div>
    <div *ngIf="state.type == 'danger'"><i class="icon-remove-sign"></i><strong>{{state.title}}!</strong> {{state.text}}.</div>
    <!--<a href="#" class="alert-link" >important alert message</a>.-->
  </div>

  <!--<div class="alert alert-info">-->
    <!--<button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>-->
    <!--<i class="icon-hand-up"></i><strong>Heads up!</strong> This alert needs your attention, but it's not super important.-->
  <!--</div>-->

  <!--<div class="alert alert-warning">-->
    <!--<button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>-->
    <!--<i class="icon-warning-sign"></i><strong>Warning!</strong> Better check yourself, you're not looking too good.-->
  <!--</div>-->

  <!--<div class="alert alert-danger nobottommargin">-->
    <!--<button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>-->
    <!--<i class="icon-remove-sign"></i><strong>Oh snap!</strong> Change a few things up and try submitting again.-->
  <!--</div>-->

</div>
