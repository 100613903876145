import {Component, OnDestroy, OnInit} from '@angular/core';
import {SEOService} from "../services/seo.service";
import {ActivatedRoute} from "@angular/router";
import {ScrollToService} from "ng2-scroll-to-el";
import {StateService} from "../services/state.service";

@Component({
  selector: 'app-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: [
    './privacy-notice.component.less',
    '../tools/header.less'
  ]
})
export class PrivacyNoticeComponent implements OnInit, OnDestroy {

  constructor(
      private scrollService: ScrollToService,
      private state: StateService,
      private activatedRoute: ActivatedRoute,
      private SEOService: SEOService

  ) {

  }

  ngOnInit() {
    // set meta tags
    const { meta } = this.activatedRoute.snapshot.data;
    this.SEOService.updateTitle(meta.title);
    this.SEOService.updateDescription(meta.description);
    // this.SEOService.updateKeywords(meta.keywords);

    this.dockShowSubscription = this.state.getDockShowChangeEmitter()
        .subscribe(dockShow => {
          this.dockShow = (dockShow == 'dock-show');
        })
  }

  ngOnDestroy() {
    this.dockShowSubscription.unsubscribe();
  }

  private dockShowSubscription: any;
  public dockShow = false;

  scrollToEl(element) {
    this.scrollService.scrollTo(element, 500, -60);
  }

}
