import { Component, OnInit } from '@angular/core';
import {ScrollToService} from "ng2-scroll-to-el";

@Component({
  selector: 'app-information-security-content',
  templateUrl: './information-security-content.component.html',
  styleUrls: ['./information-security-content.component.less']
})
export class InformationSecurityContentComponent implements OnInit {

  constructor(
      private scrollService: ScrollToService,
  ) {
    window.scroll(0,0);
  }

  ngOnInit() {
  }

  scrollToEl(element, offset?) {
    this.scrollService.scrollTo(element, 800, offset || -100);
  }
}
