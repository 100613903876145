/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tools/langselector/langselector.component.ngfactory";
import * as i3 from "./tools/langselector/langselector.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/router";
import * as i6 from "./dock/dock.component.ngfactory";
import * as i7 from "./dock/dock.component";
import * as i8 from "./services/icons.service";
import * as i9 from "./services/state.service";
import * as i10 from "./tools/order/order.component.ngfactory";
import * as i11 from "./tools/order/order.component";
import * as i12 from "./tools/order/order.service";
import * as i13 from "./services/contact.service";
import * as i14 from "./tools/alert/alert.service";
import * as i15 from "./tools/alert/alert.component.ngfactory";
import * as i16 from "./tools/alert/alert.component";
import * as i17 from "./app.component";
import * as i18 from "./services/hot.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lang-selector", [["style", "opacity: 0"]], null, null, null, i2.View_LangSelectorComponent_0, i2.RenderType_LangSelectorComponent)), i1.ɵdid(1, 114688, null, 0, i3.LangSelectorComponent, [i4.TranslateService], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "dock", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DockComponent_0, i6.RenderType_DockComponent)), i1.ɵdid(5, 4440064, null, 0, i7.DockComponent, [i5.Router, i5.ActivatedRoute, i8.IconsService, i9.StateService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "order-modal", [], null, null, null, i10.View_OrderComponent_0, i10.RenderType_OrderComponent)), i1.ɵdid(7, 4440064, null, 0, i11.OrderComponent, [i4.TranslateService, i12.OrderService, i5.Router, i13.ContactService, i14.AlertService, i5.ActivatedRoute], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "alert", [["style", "z-index: 99999999999999999999;"]], null, null, null, i15.View_AlertComponent_0, i15.RenderType_AlertComponent)), i1.ɵdid(9, 4440064, null, 0, i16.AlertComponent, [i4.TranslateService, i14.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i17.AppComponent, [i4.TranslateService, i18.HotService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i17.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
