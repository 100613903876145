import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'lang-selector',
  templateUrl: './langselector.component.html',
  styleUrls: ['./langselector.component.less']
})
export class LangSelectorComponent implements OnInit {

  constructor(private translate: TranslateService) {

  }

  ngOnInit() {
    this.switchLanguage('en');
  }

  public initLang(){
    this.translate.addLangs(["en", "ru", "sp"]);
    this.translate.setDefaultLang('en');


    // this.currentLang = 'en';
    // this.translate.use(browserLang.match(/en|ru|sp/) ? browserLang : 'en');
    // this.translate.use('en');
  }

  public currentLang: string;
  public switchLanguage(language: string) {
    this.translate.use(language);
    this.currentLang = language;
  }

}
