/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./information-security.component.less.shim.ngstyle";
import * as i1 from "../tools/header.less.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./information-security-content/information-security-content.component.ngfactory";
import * as i7 from "./information-security-content/information-security-content.component";
import * as i8 from "ng2-scroll-to-el/scrollTo.service";
import * as i9 from "./information-security.component";
import * as i10 from "../services/state.service";
import * as i11 from "../services/seo.service";
var styles_InformationSecurityComponent = [i0.styles, i1.styles];
var RenderType_InformationSecurityComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_InformationSecurityComponent, data: {} });
export { RenderType_InformationSecurityComponent as RenderType_InformationSecurityComponent };
export function View_InformationSecurityComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 16, "div", [["class", "stretched device-sm"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 14, "div", [["class", "stretched clearfix"], ["id", " wrapper"], ["style", "opacity: 1;"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 7, "header", [], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 6, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 1, "a", [["class", "logo-icon"], ["href", "/"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i2.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵdid(5, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i2.ɵeld(6, 0, null, null, 3, "a", [["class", "header-details"], ["href", "/information-security"], ["routerLink", "/information-security"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i2.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵdid(7, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i2.ɵted(8, null, [" | ", ""])), i2.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(10, 0, [["SecurityTop", 1]], null, 5, "section", [["id", "content"], ["style", "margin-top: 60px;"]], null, null, null, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 0, "div", [["class", "privacy-wrap"]], null, null, null, null, null)), (_l()(), i2.ɵeld(12, 0, null, null, 3, "div", [["class", "content-wrap"]], null, null, null, null, null)), (_l()(), i2.ɵeld(13, 0, null, null, 2, "div", [["class", "container clearfix"]], null, null, null, null, null)), (_l()(), i2.ɵeld(14, 0, null, null, 1, "app-information-security-content", [], null, null, null, i6.View_InformationSecurityContentComponent_0, i6.RenderType_InformationSecurityContentComponent)), i2.ɵdid(15, 114688, null, 0, i7.InformationSecurityContentComponent, [i8.ScrollToService], null, null), (_l()(), i2.ɵeld(16, 0, null, null, 0, "div", [["class", "icon-angle-up goTopBtn"]], [[2, "display-block", null], [2, "display-none", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToEl(i2.ɵnov(_v, 10)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 5, 0, currVal_2); var currVal_5 = "/information-security"; _ck(_v, 7, 0, currVal_5); _ck(_v, 15, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵnov(_v, 5).target; var currVal_1 = i2.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = i2.ɵnov(_v, 7).target; var currVal_4 = i2.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_6 = i2.ɵunv(_v, 8, 0, i2.ɵnov(_v, 9).transform("InformationSecurity.Title")); _ck(_v, 8, 0, currVal_6); var currVal_7 = !_co.dockShow; var currVal_8 = _co.dockShow; _ck(_v, 16, 0, currVal_7, currVal_8); }); }
export function View_InformationSecurityComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-information-security", [], null, null, null, View_InformationSecurityComponent_0, RenderType_InformationSecurityComponent)), i2.ɵdid(1, 245760, null, 0, i9.InformationSecurityComponent, [i8.ScrollToService, i10.StateService, i3.ActivatedRoute, i11.SEOService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InformationSecurityComponentNgFactory = i2.ɵccf("app-information-security", i9.InformationSecurityComponent, View_InformationSecurityComponent_Host_0, {}, {}, []);
export { InformationSecurityComponentNgFactory as InformationSecurityComponentNgFactory };
