import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.less','../header.less'],


})
export class ErrorComponent implements OnInit {

  constructor() { }


  ngAfterViewInit(){}

  ngOnInit() {}

  ngOnDestroy() {}


}


