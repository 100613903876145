import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DockComponent} from './dock/dock.component';
import {ScrollToModule} from 'ng2-scroll-to-el';


import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LangSelectorComponent} from "./tools/langselector/langselector.component";

import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

// NoopAnimationsModule
import {AlertComponent} from "./tools/alert/alert.component";
import {LoaderComponent} from "./tools/loader/loader.component";
import {OrderComponent} from "./tools/order/order.component";

import {OrderService} from "./tools/order/order.service";
import {AlertService} from "./tools/alert/alert.service";
import {FormsModule} from "@angular/forms";
import {CustomerSurveyComponent} from "./tools/customer-survey/customer-survey.component";
import {IconsService} from "./services/icons.service";
import {StateService} from "./services/state.service";

// Google analytics
import { GtagModule } from 'angular-gtag';


import {NodataComponent} from "./tools/nodata/nodata.component";
import {ErrorComponent} from "./tools/error/error.component";
import {SEOService} from "./services/seo.service";
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { SmsCalculatorComponent } from './sms-calculator/sms-calculator.component';
import { InformationSecurityComponent } from './information-security/information-security.component';
import { PrivacyNoticeContentComponent } from './privacy-notice/privacy-notice-content/privacy-notice-content.component';
import { InformationSecurityContentComponent } from './information-security/information-security-content/information-security-content.component';
import { SmsControllerComponent } from './sms-calculator/sms-controller/sms-controller.component';
import {SmsCalculatorService} from "./sms-calculator/sms-calculator.service";

@NgModule({
    declarations: [
        AppComponent,
        DockComponent,
        AlertComponent,
        NodataComponent,
        ErrorComponent,
        OrderComponent,
        LoaderComponent,
        LangSelectorComponent,
        CustomerSurveyComponent,
        PrivacyNoticeComponent,
        SmsCalculatorComponent,
        InformationSecurityComponent,
        PrivacyNoticeContentComponent,
        InformationSecurityContentComponent,
        SmsControllerComponent
    ],
    imports: [
        // BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ScrollToModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: true
        }),
        BrowserModule.withServerTransition({ appId: 'alaris-app' }),
        BrowserAnimationsModule,
        FormsModule,
        GtagModule.forRoot({ trackingId: 'UA-179548577-1', trackPageviews: true })

    ],
    providers: [OrderService, AlertService, IconsService, StateService, SEOService, SmsCalculatorService],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
