<div class="stretched device-sm">

  <div id=" wrapper" class="stretched clearfix" style="opacity: 1;">

    <header>
      <div class="logo">
        <a class="logo-icon" href="/" routerLink="/"></a>
        <a class="header-details" href="/information-security" routerLink="/information-security"> | {{ "InformationSecurity.Title" | translate }}</a>
      </div>
    </header>


    <!-- Content
      ============================================= -->
    <section id="content" style="margin-top: 60px;" #SecurityTop>

      <div class="privacy-wrap"></div>

      <div class="content-wrap">
        <div class="container clearfix">
          <app-information-security-content></app-information-security-content>
        </div>
      </div>
    </section>
  </div>

  <!-- Go To Top -->
  <div class ="icon-angle-up goTopBtn"
       [class.display-block]="!(dockShow)"
       [class.display-none]="dockShow"
       (click)="scrollToEl(SecurityTop);">
  </div>

</div>