<div class="stretched device-sm">

    <div id=" wrapper" class="stretched clearfix" style="opacity: 1;">

        <header>
            <div class="logo">
                <a class="logo-icon" href="/" routerLink="/"></a>
            </div>
        </header>

        <section id="content"  style="margin-top: 7em;">

            <div class="content-wrap">

                <div class="container clearfix">


                    <div class="col_half nobottommargin">
                        <div class="error404 center">404</div>
                    </div>

                    <div class="col_half nobottommargin col_last">

                        <div class="heading-block nobottomborder topmargin">
                            <h4>Ooopps.! The Page you were looking for, couldn't be found.</h4>
                                        <span>Try searching for the best match or browse the links below:</span>
                        </div>

                        <!--          <form action="#" method="get" role="form" class="nobottommargin">-->
                        <!--            <div class="input-group input-group-lg">-->
                        <!--              <input type="text" class="form-control" placeholder="Search for Pages...">-->
                        <!--              <span class="input-group-btn">-->
                        <!--                                    <button class="btn btn-danger" type="button">Search</button>-->
                        <!--                                </span>-->
                        <!--            </div>-->
                        <!--          </form>-->

                        <div class="row" style="padding: 0 15px;">
                        <div class="col col-xs-4 widget_links notopmargin nobottommargin">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/about">About</a></li>
                                <li><a href="/faq">FAQ</a></li>
                            </ul>
                        </div>

                        <div class="col col-xs-4 widget_links notopmargin nobottommargin">
                            <ul>
                                <li><a href="/demo">DEMO</a></li>
                                <li><a href="/messaging">Messaging</a></li>
                                <li><a href="/voice">Voice</a></li>
                            </ul>
                        </div>

                        <div class="col col-xs-4 widget_links notopmargin nobottommargin col_last">
                            <ul>
                                <li><a href="/contacts">Contacts</a></li>
                                <li><a href="/blog">Blog</a></li>
                                <li><a href="/news">News</a></li>
                            </ul>
                        </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    </div>
</div>
