import { Routes } from '@angular/router';
import { CustomerSurveyComponent } from "./tools/customer-survey/customer-survey.component";
import { ErrorComponent } from "./tools/error/error.component";
import { PrivacyNoticeComponent } from "./privacy-notice/privacy-notice.component";
import { SmsCalculatorComponent } from "./sms-calculator/sms-calculator.component";
import { sectionsMetadata } from "./tools/sections.metadata";
import { InformationSecurityComponent } from "./information-security/information-security.component";
var ɵ0 = sectionsMetadata.start, ɵ1 = sectionsMetadata.about, ɵ2 = sectionsMetadata.clients, ɵ3 = sectionsMetadata.demo, ɵ4 = sectionsMetadata.messaging, ɵ5 = sectionsMetadata.voice, ɵ6 = sectionsMetadata.expo, ɵ7 = sectionsMetadata.news, ɵ8 = sectionsMetadata.blog, ɵ9 = sectionsMetadata.start, ɵ10 = sectionsMetadata.faq, ɵ11 = sectionsMetadata.contacts, ɵ12 = sectionsMetadata.social, ɵ13 = sectionsMetadata.start, ɵ14 = sectionsMetadata.privacyNotice, ɵ15 = sectionsMetadata.informationSecurity, ɵ16 = sectionsMetadata.smsCalculator;
var routes = [
    {
        path: "",
        data: ɵ0,
        loadChildren: "../app/start/start.module#StartModule"
    },
    {
        path: "about",
        data: ɵ1,
        loadChildren: "../app/about/about.module#AboutModule"
    },
    {
        path: "clients",
        data: ɵ2,
        loadChildren: "../app/clients/clients.module#ClientsModule"
    },
    {
        path: "demo",
        data: ɵ3,
        pathMatch: 'prefix',
        loadChildren: "../app/demo/demo.module#DemoModule"
    },
    {
        path: 'messaging',
        data: ɵ4,
        loadChildren: "../app/messaging/messaging.module#MessagingModule",
    },
    {
        path: 'voice',
        data: ɵ5,
        loadChildren: "../app/voice/voice.module#VoiceModule",
    },
    {
        path: 'charity',
        loadChildren: "../app/charity/charity.module#CharityModule",
    },
    {
        path: "exhibition",
        data: ɵ6,
        loadChildren: "../app/exhibition/exhibition.module#ExhibitionModule"
    },
    {
        path: "news",
        data: ɵ7,
        loadChildren: "../app/news/news.module#NewsModule"
    },
    {
        path: "blog",
        data: ɵ8,
        loadChildren: "../app/blog/blog.module#BlogModule"
    },
    {
        path: "account",
        data: ɵ9,
        loadChildren: "../app/login/login.module#LoginModule"
    },
    {
        path: "faq",
        data: ɵ10,
        loadChildren: "../app/faq/faq.module#FaqModule"
    },
    {
        path: "contacts",
        data: ɵ11,
        loadChildren: "../app/contacts/contacts.module#ContactsModule"
    },
    {
        path: "social",
        data: ɵ12,
        loadChildren: "../app/social/social.module#SocialModule"
    },
    {
        path: "customer-survey",
        data: ɵ13,
        component: CustomerSurveyComponent
    },
    {
        path: "privacy-notice",
        data: ɵ14,
        component: PrivacyNoticeComponent
    },
    {
        path: "information-security",
        data: ɵ15,
        component: InformationSecurityComponent
    },
    {
        path: "sms-calculator",
        data: ɵ16,
        component: SmsCalculatorComponent
    },
    // // otherwise redirect to home
    // {path: '**', redirectTo: ''}
    //Wild Card Route
    { path: '**', pathMatch: 'full', component: ErrorComponent },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16 };
