﻿import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {config} from './config';
import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ContactService {
    constructor(private http: HttpClient) {
        this.requestModel = <any>{};
    }

    // public path : string = '/exhibitions';

    private handleError(error: HttpErrorResponse) {
        console.log(error);
        // return an observable with a user friendly message
        return throwError('Error! something went wrong.');
    }

    requestModel: any;

    public setModel(requestModel: any) {
        this.requestModel = requestModel;
        // this.emitDataChangeEvent(this.requestModel);
        // console.log(this.requestModel)
    }


    public getModel() {
        return this.requestModel;
    }

    dataChange: EventEmitter<any> = new EventEmitter();

    public emitDataChangeEvent(mode) {
        this.dataChange.emit(mode);
    }

    public getDataChangeEmitter() {
        return this.dataChange;
    }

    public mailForm: {
        from: string,
        email: string,
        text: string,
        title: string
    }

    // ---------- Feedback Mail ----------
    generateFeedbackMail() {
        this.mailForm = <any>{};
        this.mailForm.email = this.requestModel.Email;
        this.mailForm.from = this.requestModel.Name;
        this.mailForm.title = 'Feedback from site: ' + this.requestModel.Item;
        this.mailForm.text = "" +

        "Name: " + this.requestModel.Name + "\n" +
        "Company: " + this.requestModel.Company + "\n" +
        "Country: " + this.requestModel.Country + "\n" +
        "Phone: " + this.requestModel.Phone + "\n" +
        "Email: " + this.requestModel.Email + "\n" +
        "Text: " + this.requestModel.Message + "\n";

    }

    MailFeedbackForm(): Observable<any> {

        this.generateFeedbackMail();
        // console.log('MAIL CONTACT FORM ---> ', this.mailForm);
        return this.http.post(`${config.apiUrl}/php/inc/mail.php`,
            this.mailForm,
            {headers: {'Content-Type': 'application/json'}}).pipe(
            map((res) => {
                // console.log('RESPONSE ---> ', res)
                return res;
            }), catchError(this.handleError));
    }

    // ---------- Contact Mail ----------
    generateContactMail() {
        this.mailForm = <any>{};
        this.mailForm.email = this.requestModel.Email;
        this.mailForm.from = this.requestModel.Name;
        this.mailForm.title = 'Message from contact form';
        this.mailForm.text = "" +

        "Name: " + this.requestModel.Name + "\n" +
        "Company: " + this.requestModel.Company + "\n" +
        "Category: " + this.requestModel.Category + "\n" +
        "Phone: " + this.requestModel.Phone + "\n" +
        "Email: " + this.requestModel.Email + "\n" +
        "Text: " + this.requestModel.Message + "\n";
    }

    MailContactForm(): Observable<any> {
        this.generateContactMail();
        return this.http.post(`${config.apiUrl}/php/inc/mail.php`,
            this.mailForm,
            {headers: {'Content-Type': 'application/json'}}).pipe(
            map((res) => {
                // console.log('RESPONSE ---> ', res)
                return res;
            }), catchError(this.handleError));
    }

    // // ---------- SMART Mail ----------
    // generateSmartMail() {
    //     this.mailForm = <any>{};
    //     this.mailForm.email = this.requestModel.Email;
    //     this.mailForm.from = this.requestModel.Name;
    //     this.mailForm.title = 'SMART SELECT';
    //
    //     this.mailForm.text = "" +
    //     "Market: " + this.requestModel.Smart.Market.VoiceWholesale ? 'Voice Wholesale, ' : '' +
    //     this.requestModel.Smart.Market.SMSHubing ? 'SMS Hubing, ' : '' +
    //     this.requestModel.Smart.Market.EnterpriseMessaging ? 'Enterprise Messaging, ' : '' +
    //     this.requestModel.Smart.Market.Custom ? 'I have in mind something else: ' + this.requestModel.Smart.Market.CustomText : '' +
    //     "\n" +
    //     "Premises: " +
    //     this.requestModel.Smart.Premises.SaaS ? 'SaaS, ' : '' +
    //     this.requestModel.Smart.Premises.MyPremises ? 'My Premises, ' : '' +
    //     this.requestModel.Smart.Premises.Custom ? 'I have in mind an exceptional setup: ' + this.requestModel.Smart.Premises.CustomText : '' +
    //     "\n" +
    //     "Payment Structure: " +
    //     this.requestModel.Smart.PaymentStructure.MRC ? 'MRC, ' : '' +
    //     this.requestModel.Smart.PaymentStructure.OneTime ? 'One Time, ' : '' +
    //     this.requestModel.Smart.PaymentStructure.InstalmentPlan ? 'Instalment Plan, ' : '' +
    //     this.requestModel.Smart.PaymentStructure.Custom ? 'My commercial imagination is beyond standard: ' + this.requestModel.Smart.PaymentStructure.CustomText : '' +
    //     "\n" +
    //     "Premises: " +
    //     this.requestModel.Smart.BusinessLevel.HelpMeToStart ? 'Help me to start, ' : '' +
    //     this.requestModel.Smart.BusinessLevel.MyGrowingBusiness ? 'My Growing Business, ' : '' +
    //     this.requestModel.Smart.BusinessLevel.Custom ? 'I have in mind an exceptional setup: ' + this.requestModel.Smart.BusinessLevel.CustomText : '' +
    //     "\n" +
    //
    //     "Name: " + this.requestModel.Name + "\n" +
    //     "Company: " + this.requestModel.Company + "\n" +
    //     "Country: " + this.requestModel.Country + "\n" +
    //     "Phone: " + this.requestModel.Phone + "\n" +
    //     "Email: " + this.requestModel.Email + "\n" +
    //     "Text: " + this.requestModel.Message + "\n";
    //
    // }
    //
    // MailSmartForm(): Observable<any> {
    //     this.generateSmartMail();
    //     return this.http.post(`${config.apiUrl}/php/inc/mail.php`,
    //         this.mailForm,
    //         {headers: {'Content-Type': 'application/json'}}).pipe(
    //         map((res) => {
    //             // console.log('RESPONSE ---> ', res)
    //             return res;
    //         }), catchError(this.handleError));
    // }


    // ---------- Product Mail ----------
    generateProductMail() {

        // Company: "asdasd"
        // Country: "asdasd"
        // Email: "asdasd"
        // Message: "asdasd"
        // MonthlyVolume: "asdasd"
        // Name: "asdasd"
        // Phone: "asdasd"
        // ProductType: "inVoice"
        // SwitchBrand: "asdasd"
        // Title: "I have my own switch, I want Alaris inVoice LCR+Billing


        this.mailForm = <any>{};
        this.mailForm.email = this.requestModel.Email;
        this.mailForm.from = this.requestModel.Name;
        this.mailForm.title = this.requestModel.ProductType + ' request: ' + this.requestModel.Title;
        this.mailForm.text = "" +
        "Name: " + this.requestModel.Name + "\n" +
        "Company: " + this.requestModel.Company + "\n" +
        "Country: " + this.requestModel.Country + "\n" +
        "Phone: " + this.requestModel.Phone + "\n" +
        "Email: " + this.requestModel.Email + "\n" +
        "Monthly Volume: " + this.requestModel.MonthlyVolume + "\n" +
        "Switch Brand: " + this.requestModel.SwitchBrand + "\n" +
        "Product Type: " + this.requestModel.ProductType + "\n" +
        // "Request: " + this.requestModel.Request + "\n" +
        "Text: " + this.requestModel.Message + "\n";
    }

    MailProductForm(): Observable<any> {

        this.generateProductMail();
        // console.log('MAIL PRODUCT FORM ---> ', this.mailForm);
        return this.http.post(`${config.apiUrl}/php/inc/mail.php`,
            this.mailForm,
            {headers: {'Content-Type': 'application/json'}}).pipe(
            map((res) => {
                // console.log('RESPONSE ---> ', res)
                return res;
            }), catchError(this.handleError));
    }

}

