// import {ICarrier} from "./carriers/carriers.component";

import {EventEmitter} from "@angular/core";

export class AlertService {

  constructor() {

    this.state = <IAlert> {
      state: "hide",
      type: "success",
      text: "Some text",
      title: "Title",
      action: ""
    }

  }

  stateChange: EventEmitter<IAlert> = new EventEmitter();

  public emitStateChangeEvent(state: IAlert)  {
    this.stateChange.emit(state);
  }

  public getStateChangeEmitter() {
    return this.stateChange;
  }

  public setState(state: IAlert){
    this.state.state = state.state ? state.state : "show";
    this.state.type = state.type ? state.type : this.state.type ? this.state.type : 'success';
    this.state.title = state.title ? state.title : this.state.title ? this.state.title : "Title";
    this.state.text = state.text ? state.text :  this.state.text  ?  this.state.text : "Some message";
    this.state.action = state.action ? state.action : this.state.action ? this.state.action : "";

    // console.log('ALERT setState: ', this.state)

    this.emitStateChangeEvent(this.state);
  }

  public getState(): IAlert {
    return this.state;
  }

  public state : IAlert;

}


export interface IAlert {
  state: 'show' | 'hide',
  type: 'danger' | 'success' | 'info' | 'warning',
  title: string,
  text: string,
  action: string
  delay: number;
}
