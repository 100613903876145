<!-- Post Content
============================================= -->
<div class="postcontent clearfix app-card-container" style="margin-top: 1em; margin-bottom: 7em;">

    <div class="single-post nobottommargin ">

        <!-- Single Post
        ============================================= -->
        <div class="entry clearfix noborder nobottommargin">

            <!-- Entry Title
            ============================================= -->
            <div class="entry-title">
                <h1>ALARISLABS PRIVACY POLICY NOTICE</h1>
            </div><!-- .entry-title end -->


            <!-- Entry Content
            ============================================= -->
            <div class="entry-content">


                <div>
                    <p>ALARISLABS is a software developing company that provides a suite of products enabling voice
                        wholesale and SMS hubbing business. We are aware of the importance and necessity of developing
                        and improving measures and means of ensuring information security and data protection in the
                        context of the development of international legislation and regulations for the protection of
                        information and personal data, as well as in the context of customer expectations. We respect
                        your privacy and we are committed to keeping all your personal data secure. This Privacy Policy
                        Notice explains what types of your personal data we collect or process and how we use, disclose
                        and protect it. The Privacy Policy stipulated in this Notice is applicable to all of the
                        ALARISLABS websites, products and services. <strong>“ALARISLABS”, “we” or “us”</strong>
                        shall mean <strong>Alarislabs Pte Ltd.</strong>
                    </p>

                    <div class="blue-text toc-for-mobile">
                        For your convenience we have structured this Privacy Policy notice into the following sections:
                        <ul class="content-paddingleft toc-for-mobile">
                            <li (click)="scrollToEl(notice1);">1. PURPOSE OF THIS NOTICE</li>
                            <li (click)="scrollToEl(notice2);">2. TYPES OF INFORMATION WE COLLECT</li>
                            <li (click)="scrollToEl(notice3);">3. HOW WE USE AND DISCLOSE YOUR INFORMATION</li>
                            <li (click)="scrollToEl(notice4);">4. HOW WE PROTECT YOUR INFORMATION</li>
                            <li (click)="scrollToEl(notice5);">5. HOW LONG WE RETAIN YOUR PERSONAL DATA</li>
                            <li (click)="scrollToEl(notice6);">6. YOUR RIGHTS AS DATA SUBJECTS</li>
                            <li (click)="scrollToEl(notice7);">7. ACCURACY OF PERSONAL DATA</li>
                            <li (click)="scrollToEl(notice8);">8. CONTACT INFORMATION</li>
                        </ul>
                    </div>



                    <h3 #notice1>1. PURPOSE OF THIS NOTICE</h3>
                    <p>
                        This Privacy Policy Notice provides mandatory information as required under Articles 13 and 14
                        of the European General Data Protection Regulation (GDPR) regarding the transparency of personal
                        data processing.<br><br>
                        For the purposes of this Privacy Policy Notice the term “Personal Data” shall be understood in
                        line with Article 4 of the GDPR and mean any information relating to an identified or
                        identifiable natural person (‘data subject’); an identifiable natural person is one who can be
                        identified, directly or indirectly, in particular by reference to an identifier such as a name,
                        an identification number, location data, an online identifier or to one or more factors specific
                        to the physical, physiological, genetic, mental, economic, cultural or social identity of that
                        natural person.
                    </p>

                    <h3 #notice2>2. TYPES OF INFORMATION WE COLLECT</h3>
                    <p>
                        In the course of the commercial activities ALARISLABS may handle the Personal Data either as a
                        <strong>Data Controller</strong> (when information is collected from you directly) or as a
                        <strong>Data Processor</strong> (when information is obtained from our customers in the process
                        of provision of the related product or service). Below you can find for what purposes and what
                        Personal Data we collect from you.
                    </p>

                    <h4 #notice2.1>2.1. Information, collected from you directly (ALARISLABS as Data Controller)</h4>
                    <p>
                        In order to provide our products and perform the services we are required to collect, store and process some of your Personal Data. This might be done when you register or fill out the message forms on our website (<a href="https://www.alarislabs.com/" routerLink="/">https://www.alarislabs.com/</a>), directly contact us through e-mail or telephone, or register to our services.
                    </p>

                    <p>
                        Types of information: The nature of the services you are requesting will determine the kind of Personal Data we might ask for, though such information may include without limitation the following:
                    </p>
                    <ul class="content-paddingleft">
                        <li>basic Personal Data (such as first name; family name; position in the company; company name; business email address; business phone number; business address; city; postcode; country);</li>
                        <li>information that you choose to share on ALARISLABS websites which may be considered Personal Data.</li>
                    </ul>

                    <p>
                        Purpose of processing: The purposes for which we collect and store the aforementioned Personal Data also depend on the nature of your request and scope of our cooperation, though such purposes may include where appropriate without limitation the following:
                    </p>
                    <ul class="content-paddingleft">
                        <li>for prompt provision of answers and appropriate information regarding our products and services.</li>
                        <li>for delivering to you of timely updates regarding our products and services as well as delivering
                            of our newsletters information which might be useful for you as our customer (or potential
                            customer).</li>
                        <li>for appropriate ongoing communication with you, responding to your requests and inquiries.</li>
                        <li>for provision of our commercial offers.</li>
                        <li>for subsequent conclusion of legally binding contracts.</li>
                        <li>for creating and maintaining your user accounts,</li>
                        <li>for creating and maintaining the accounting and analytics tools developed for you and for sharing
                            related reports and analysis with you.</li>
                        <li>for appropriate provision to you of the requested services and technical support,</li>
                        <li>in order to comply with and in order to assess compliance with applicable laws, rules and
                            regulations, and internal policies and procedures,</li>
                        <li>for ongoing review, monitoring and improvement of products and services, preventing any
                            potential disruptions</li>
                        <li>to allow you to use and access the functionality provided by our products;</li>
                    </ul>

                    <p>
                        All the Personal Data collected from you may be stored in a secure data storage in Singapore, Paris, France and Frankfurt am Main, Germany, and will not be used for the purposes not related to provision of our products or services.
                    </p>

                    <p>
                        We do not transfer any Personal Data provided by our customers from their clients outside EEA area, unless it is otherwise requested by our customers in respect of Personal Data in their responsibility or is required by applicable law. However, ALARISLABS leads its commercial activities on a global basis. Consequently, the Personal Data might be transferred to locations outside of your country. In case a transfer of Personal Data to another country is required by our customer, we will use best endeavors to ensure that the Data is protected and transferred in a manner consistent with the applicable legal requirements.
                    </p>

                    <h4 #notice2.2>2.2. Information obtained from our customers solely in the course of product or service provision (ALARISLABS as Data Processor)</h4>

                    <p>
                        We conclude an appropriate Data Protection Agreement with each of our customers. In compliance with the requirements of such Data Protection Agreement we process Personal Data lawfully collected by our customers for appropriate provision of our products and services. Such scope shall in particular include use, transmission, storage and, when instructed by the customer, erasure and/or destruction of Personal Data. In these cases our customers act ac Data Controllers and therefore we process the Personal Data exclusively in accordance with their instructions. Data of the Customer’s clients (including certain data which may be classified as “Personal Data” for the purposes of the GDPR) will be registered in ALARISLABS system, in a way which enables our customers to invoice its clients.
                    </p>

                    Types of information:
                    <ul class="content-paddingleft">
                        <li>Business contact details of the Customer’s clients (without limitation: name, title, business phone number, mobile number, email address, fax number and business address).</li>
                        <li>Sender and receiver phone number but not message content, retention functions are completed with the obfuscation of SMS content (pseudonymisation).</li>
                    </ul>

                    Purpose of processing:
                    <ul class="content-paddingleft">
                        <li>for performance of our contractual obligations with our customers;</li>
                        <li>for enabling our customers to timely invoice their clients;</li>
                        <li>for the administration and maintenance of databases storing Personal Data;</li>
                        <li>in order to comply with and in order to assess compliance with applicable laws, rules and
                            regulations, and internal policies and procedures</li>
                    </ul>

                    <p>
                        We store and process Personal Data provided by our customers from their clients in our data centers in Paris, France and Frankfurt am Main, Germany, both of which are duly certified under ISO 27001.
                    </p>

                    <p>
                        ALARISLABS leads its commercial activities on a global basis. Consequently, the Personal Data might be transferred to locations outside of your country. In case a transfer of Personal Data to another country is required by our customer, we will use best endeavors to ensure that the Data is protected and transferred in a manner consistent with the applicable legal requirements.
                    </p>

                    <h3 #notice3>3. HOW WE USE AND DISCLOSE YOUR INFORMATION</h3>
                    <p>
                        ALARISLABS undertakes all reasonable efforts to ensure that the Personal Data is accessed only by employees of ALARISLABS exclusively for the purposes described in this Privacy Policy Notice. All ALARISLABS employees are subject to appropriate data protection and confidentiality obligations.
                    </p>

                    We may also be required to transfer and disclose your Personal Data processed by us to third parties in the following cases and for the following purposes:
                    <ul class="content-paddingleft">
                        <li>to the extent required by law (in particular, when we might be obliged to disclose your Personal Data in order to comply with any legal obligation (including, without limitation, tax reporting requirements and disclosures to regulators), or to establish, exercise or defend our legal rights.);</li>
                        <li>at the request of governmental authorities conducting an audit or investigation;</li>
                        <li>in case of change of ownership or control (without limitation: mergers, acquisitions, restructuring,
                            sale of assets etc.)</li>
                    </ul>
                    <p>
                        We process all requests to disclose Personal Data with due care and will proceed to the disclosure only in case there is an actual legal basis for it.
                    </p>


                    <h3 #notice4>4. HOW WE PROTECT YOUR INFORMATION</h3>

                    <p>We use our best endeavors to ensure security and privacy of your Personal Data and our information systems. In accordance with our Information Security Management System (ISMS) and the requirements of GDPR, technical and organizational security measures for the protection of Personal Data are implemented on the ongoing basis, including where appropriate firewall, monitoring system, user access control, partial separation from Internet, data obfuscation of message content, backing up and encryption.</p>
                    <p>The information is protected online and offline. The employees are granted with a restricted access to your Personal Data only for the purpose and in the scope necessary for performance of their specific tasks. The servers and computers where the Personal Data are kept in a secured environment, preventing unauthorized access. Physical access to areas where Personal Data is stored and processed is limited to authorized employees with appropriated Data protection and confidentiality obligations.</p>


                    <h3 #notice5>5. HOW LONG WE RETAIN YOUR PERSONAL DATA</h3>
                    <p>We retain and process the Personal Data provided to us by our customers from their clients only for as long as the relevant data is required for the performance of our contractual obligations with the respective customers, or for compliance with legal (in particular commercial and fiscal) obligations applicable to us and our business, whichever is the longer. After the lapse of the applicable period, the relevant Personal Data provided by the Customer is erased, unless the deletion is practically not possible due to technical limitations, in which case such Personal Data will be blocked from further use.</p>
                    <p>Business contact details will be retained unless the respective data subject requests return or erasure of such data</p>
                    <p>EDR data will be retained following termination of the legal contract with a related customer to the extent required to facilitate post termination rights and obligations, unless the respective data subject requests return or erasure of such data.</p>

                    <h3 #notice6>6. YOUR RIGHTS AS DATA SUBJECTS</h3>
                    In all cases in which we collect, use or store your Personal Data, you as a Data Subject possess the following principal rights under the GDPR:

                    <ul class="content-paddingleft">
                        <li>the right to obtain information regarding the way of processing of your Personal Data and access to the Personal Data which we store about you, including the right to access to a copy of the personal data which we hold about you;</li>
                        <li>the right to withdraw your consent to the processing of your Personal Data. Please note, that we may still be entitled to process your Personal Data if there is a legitimate reason for doing so (i.a. obligation to comply with legal requirements);</li>
                        <li>the right to request rectification of your Personal Data if it is inaccurate or incomplete;</li>
                        <li>the right to request erasure of your Personal Data where applicable unless otherwise required by law;</li>
                        <li>the right to request that we restrict our processing of your Personal Data where applicable (e.i.
                            newsletters, marketing campaigns) unless otherwise required by law.</li>
                        <li>the right to lodge a complaint in case you find your rights violated by our handling of your Personal
                            Data.</li>
                    </ul>

                    <p>
                        You can exercise your rights as Data Subjects by contacting us through contract details indicated below in the relative section. Requests regarding any data provided to us by our customers from their clients shall be lodged by our respective customer, who has collected such data as a Data Controller.
                    </p>

                    <p>
                        We will respond to your requests concerning the aforementioned matters as soon as reasonably possible. Should we not be able to respond to your request within thirty (30) days after receiving your request, we will inform you in writing within thirty (30) days of the time by which we will be able to respond to your request.
                    </p>

                    <h3 #notice7>7. ACCURACY OF PERSONAL DATA</h3>
                    <p>
                        We generally rely on Personal data provided by you (or your authorised representative). In order to ensure that your personal data is current, complete and accurate, please update us if there are changes to your personal data by informing our Data Protection Officer in writing via email at the contact details provided below or through contact form on our website or through contact form on our website.
                    </p>

                    <h3 #notice8>8. CONTACT INFORMATION</h3>
                    <p>
                        Should you have any questions or concerns about processing of your Personal Data by ALARISLABS, or about this Policy, please do not hesitate to contact us by sending an email to <a href="mailto:dpo@alarislabs.com">dpo@alarislabs.com</a> or through contact form on our website <a href="https://www.alarislabs.com/contacts" routerLink="/contacts">https://www.alarislabs.com/contacts</a>.
                    </p>

                    <p>
                        We use our best efforts to address and resolve privacy issues promptly and effectively. In case you are not satisfied with the response you receive from our Data Protection Officer, you are entitled escalate your concern to our management. Upon request, our Data Protection Officer will provide you with the necessary contact information.
                    </p>

                    <p>
                        Please note that ALARISLABS reserves the right to amend this Privacy Police Notice as it might be necessary from time to time due to the changes in applicable legislation. Any amendments will be effective upon their publication. We will notify you about significant changes to this Privacy Police Notice by sending a respective notice to your email address.
                    </p>

                    <br>
                    <p>Last update: February 9th, 2022.</p>
                </div>

                <div class="clear"></div>

            </div>


        </div><!-- .entry end -->

    </div>

</div>
<!-- .postcontent end -->


<!-- Sidebar
============================================= -->
<div class="sidebar nobottommargin col_last clearfix" style="margin-top: 40px;">
    <div class="sidebar-widgets-wrap">

        <div class="widget clearfix">

            <div class="tabs nobottommargin clearfix ui-tabs ui-widget ui-widget-content ui-corner-all"
                 id="sidebar-tabs">

                <div>
                    <h4 class="nobottommargin">{{'PrivacyNotes.TOC' | translate}}</h4>
                    <hr class="nobottommargin">
                </div>

                <div class="tab-container">

                    <div class="tab-content clearfix ui-tabs-panel ui-widget-content ui-corner-bottom" id="tabs-1"
                         aria-labelledby="ui-id-1" role="tabpanel" aria-expanded="true" aria-hidden="false"
                         style="display: block;">
                        <div id="popular-post-list-sidebar">

                            <div class="spost clearfix">

                                <div class="entry-c">
                                    <ul >
                                        <li (click)="scrollToEl(notice1);" class="toc-item">1. PURPOSE OF THIS NOTICE</li>
                                        <li (click)="scrollToEl(notice2);" class="toc-item">2. TYPES OF INFORMATION WE COLLECT</li>
                                        <li (click)="scrollToEl(notice3);" class="toc-item">3. HOW WE USE AND DISCLOSE YOUR INFORMATION</li>
                                        <li (click)="scrollToEl(notice4);" class="toc-item">4. HOW WE PROTECT YOUR INFORMATION</li>
                                        <li (click)="scrollToEl(notice5);" class="toc-item">5. HOW LONG WE RETAIN YOUR PERSONAL DATA</li>
                                        <li (click)="scrollToEl(notice6);" class="toc-item">6. YOUR RIGHTS AS DATA SUBJECTS</li>
                                        <li (click)="scrollToEl(notice7);" class="toc-item">7. ACCURACY OF PERSONAL DATA</li>
                                        <li (click)="scrollToEl(notice8);" class="toc-item">8. CONTACT INFORMATION</li>
                                    </ul>

                                </div>
                            </div>

                        </div>
                    </div>


                </div>

            </div>

        </div>

        <div class="clear" style="margin-top: 20px"></div>


    </div>

</div>
<!--.sidebar end-->


