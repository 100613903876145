import {EventEmitter} from "@angular/core";

export class OrderService {

    constructor() {

    }

    dataChange: EventEmitter<any> = new EventEmitter();

    public emitDataChangeEvent(mode) {
        // console.log(mode)
        this.dataChange.emit(mode);
    }

    public getDataChangeEmitter() {
        return this.dataChange;
    }

    public setData(mode: any) {
        this.emitDataChangeEvent(mode);
    }

}

export interface IOrderRequest {
    Name: string,
    Email: string,
    Phone: string,
    Company: string,
    Country: string,
    Message: string,
    Title: string,
    ProductType: string
}

