import {Component, OnInit} from '@angular/core';
import {ScrollToService} from "ng2-scroll-to-el";
import {StateService} from "../../services/state.service";

@Component({
  selector: 'app-privacy-notice-content',
  templateUrl: './privacy-notice-content.component.html',
  styleUrls: ['./privacy-notice-content.component.less']
})
export class PrivacyNoticeContentComponent implements OnInit {


  constructor(
      private scrollService: ScrollToService,
  ) {
    window.scroll(0,0);
  }

  ngOnInit() {
  }

  scrollToEl(element, offset?) {
    this.scrollService.scrollTo(element, 800, offset || -100);
  }

}
