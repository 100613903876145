/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./langselector.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./langselector.component";
import * as i4 from "@ngx-translate/core";
var styles_LangSelectorComponent = [i0.styles];
var RenderType_LangSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LangSelectorComponent, data: {} });
export { RenderType_LangSelectorComponent as RenderType_LangSelectorComponent };
export function View_LangSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "lang-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "lang active"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "lang-selector"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "lang-selector-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchLanguage("en") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["English"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "lang-selector-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchLanguage("ru") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0420\u0443\u0441\u0441\u043A\u0438\u0439"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "lang-selector-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchLanguage("sp") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0418\u0441\u043F\u0430\u043D\u0441\u043A\u0438\u0439"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "lang active"; var currVal_1 = _co.currentLang; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_LangSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lang-selector", [], null, null, null, View_LangSelectorComponent_0, RenderType_LangSelectorComponent)), i1.ɵdid(1, 114688, null, 0, i3.LangSelectorComponent, [i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LangSelectorComponentNgFactory = i1.ɵccf("lang-selector", i3.LangSelectorComponent, View_LangSelectorComponent_Host_0, {}, {}, []);
export { LangSelectorComponentNgFactory as LangSelectorComponentNgFactory };
