import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
declare const WebAnketa: any;

// import { DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'customer-survey',
  templateUrl: './customer-survey.component.html',
  styleUrls: ['./customer-survey.component.less', '../header.less'],
})

export class CustomerSurveyComponent implements OnInit {

  constructor() {
  }

  @ViewChild('iframe') iframe: ElementRef;

  ngAfterViewInit() {

    // console.log('Изучение мнения клиентов Alaris Labs (2019 г.)');
    // var WebAnketaParams = ["68vkjchj6gqp4d1gc8r6crb6", "Изучение мнения клиентов Alaris Labs (2019 г.)"];
    // document.write(unescape("<"+"script src='http"+((new RegExp("^https:")).test(document.URL) ? "s" : "")+"://webanketa.com/direct/js/embed.js' type='text/javascript'><"+"/script>"));

    // WebAnketa();
    this.iframe.nativeElement.setAttribute('src', 'http://webanketa.com/forms/68vkjchj6rqp8d9pcgv3ed31/');
  }

  ngOnInit() {
  }

}




