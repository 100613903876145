import { Meta, Title } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/platform-browser";
var SEOService = /** @class */ (function () {
    function SEOService(dom, titleSvc, metaSvc) {
        this.dom = dom;
        this.titleSvc = titleSvc;
        this.metaSvc = metaSvc;
    }
    SEOService.prototype.updateTitle = function (title) {
        this.titleSvc.setTitle(title);
    };
    SEOService.prototype.updateDescription = function (content) {
        this.metaSvc.updateTag({ name: 'description', content: content });
    };
    SEOService.prototype.updateKeywords = function (content) {
        this.metaSvc.updateTag({ name: 'keywords', content: content });
    };
    SEOService.prototype.createCanonicalLink = function (url) {
        var link = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', url);
        this.dom.head.appendChild(link);
    };
    SEOService.ngInjectableDef = i0.defineInjectable({ factory: function SEOService_Factory() { return new SEOService(i0.inject(i1.DOCUMENT), i0.inject(i2.Title), i0.inject(i2.Meta)); }, token: SEOService, providedIn: "root" });
    return SEOService;
}());
export { SEOService };
