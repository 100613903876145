import { Component } from '@angular/core';
import { Router }          from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {HotService} from "./services/hot.service";



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'AlarisLabs the Portal';

  constructor(
      private translate: TranslateService,
      private hotService: HotService
  ){
    // this.checkRoute();
    this.animation = true;

    translate.addLangs(["en", "ru", "sp"]);
    translate.setDefaultLang('en');

    let browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|ru|sp/) ? browserLang : 'en');
    translate.use('en');


    this.checkHotList();
  }

  // public switchLanguage(language: string) {
  //   this.translate.use(language);
  // }

  public router: Router;

  public animation = true;//"hidden";

  public changeState(state){

    this.animation = state;
    // console.log(state);
  }

  // public checkRoute(){
  //   // debugger;
  //   console.log(this.router)
  // }



  checkHotList(take?, skip?) {
    this.hotService.checkList(take, skip).subscribe(
        (res: any[]) => {
          console.log('checkList res:', res)
        },
        (err) => {

        }
    );
  }
}


