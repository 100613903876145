/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-survey.component.less.shim.ngstyle";
import * as i1 from "../header.less.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/router";
import * as i4 from "./customer-survey.component";
var styles_CustomerSurveyComponent = [i0.styles, i1.styles];
var RenderType_CustomerSurveyComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_CustomerSurveyComponent, data: {} });
export { RenderType_CustomerSurveyComponent as RenderType_CustomerSurveyComponent };
export function View_CustomerSurveyComponent_0(_l) { return i2.ɵvid(0, [i2.ɵqud(402653184, 1, { iframe: 0 }), (_l()(), i2.ɵeld(1, 0, null, null, 8, "div", [["class", "stretched clearfix"], ["id", " wrapper"], ["style", "opacity: 1;"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 4, "header", [], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 3, "div", [["class", "logo"], ["routerLink", "/"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i2.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵdid(4, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i2.Renderer2, i2.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i2.ɵeld(5, 0, null, null, 1, "div", [["class", "header-details"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" | Customer Satisfaction Survey (2019)"])), (_l()(), i2.ɵeld(7, 0, null, null, 2, "section", [["class", "customer-survey-wrapper"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 1, "div", [["class", "customer-survey-container"]], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, [[1, 0], ["iframe", 1]], null, 0, "iframe", [["height", "1600"], ["width", "750"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "/"; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_CustomerSurveyComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "customer-survey", [], null, null, null, View_CustomerSurveyComponent_0, RenderType_CustomerSurveyComponent)), i2.ɵdid(1, 4308992, null, 0, i4.CustomerSurveyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerSurveyComponentNgFactory = i2.ɵccf("customer-survey", i4.CustomerSurveyComponent, View_CustomerSurveyComponent_Host_0, {}, {}, []);
export { CustomerSurveyComponentNgFactory as CustomerSurveyComponentNgFactory };
