import {EventEmitter} from "@angular/core";

export class IconsService {

    constructor() {
        this.setIconList();
        this.setSocialIconList();
    }

    // /**
    //  *
    //  * site state service
    //  */
    // modeChange: EventEmitter<string> = new EventEmitter();
    // public mode: string = 'myself'; // 'tutorial'
    //
    // public emitModeChangeEvent(mode) {
    //   this.modeChange.emit(mode);
    // }
    //
    // public getModeChangeEmitter() {
    //   return this.modeChange;
    // }
    // public setMode(mode){
    //   if(mode == 'myself' || mode == 'tutorial'){
    //     this.mode = mode;
    //     this.emitModeChangeEvent(mode);
    //   }
    // }


    /**
     * Navigation Icons
     */

    public setIcons() {
        this.setIconList();
    }

    public getIcons() {
        return this.iconList;
    }

    public iconList: IIconItem[];

    private setIconList() {
        this.iconList = [];


        this.iconList.push(<IIconItem>{
            routerLink: '/messaging',
            class: 'messaging',
            title: 'Messaging',
            descr: 'Interconnect Billing<br>LCR Engine<br>MNP Server',
            bubblePosition: 9,
            deskPosition: 2,
        });


        this.iconList.push(<IIconItem>{
            routerLink: '/voice',
            class: 'voice',
            title: 'inVoice',
            descr: 'A2P Hubbing<br>Enterprise Messaging<br>SMS Firewall<br>MNP Server',
            bubblePosition: 8,
            deskPosition: 3,
        });


        if(window.innerWidth > 768)
        this.iconList.push(<IIconItem>{
            routerLink: '/demo',
            class: 'demo',
            title: 'Demo',
            bubblePosition: 2,
            deskPosition: 4,
        });

        this.iconList.push(<IIconItem>{
            routerLink: '/about',
            class: 'about',
            title: 'About',
            bubblePosition: 0,
            deskPosition: 1,
        });

        this.iconList.push(<IIconItem>{
            routerLink: '/clients',
            class: 'clients',
            title: 'Clients',
            bubblePosition: 6,
            deskPosition: 7,
        });

        this.iconList.push(<IIconItem>{
            routerLink: '/news',
            class: 'news',
            title: 'News',
            bubblePosition: 1,
            deskPosition: 5,
        });


        this.iconList.push(<IIconItem>{
            routerLink: '/faq',
            class: 'faq',
            title: 'FAQ',
            bubblePosition: 3,
            deskPosition: 8,
        });

        this.iconList.push(<IIconItem>{
            routerLink: '/exhibition',
            class: 'expo',
            title: 'Exhibitions',
            bubblePosition: 7,
            deskPosition: 6,
        });

        this.iconList.push(<IIconItem>{
            routerLink: '/contacts',
            class: 'contacts',
            title: 'Contacts',
            bubblePosition: 4,
            deskPosition: 11,
        });

        // this.iconList.push(<IIconItem>{
        //     routerLink: '/account',
        //     class: 'account',
        //     title: 'Account',
        //     bubblePosition: 5,
        //     deskPosition: 9,
        // });

        this.iconList.push(<IIconItem>{
            routerLink: '/blog',
            class: 'blog',
            title: 'Blog',
            bubblePosition: 5,
            deskPosition: 9,
        });


        this.iconList.push(<IIconItem>{
            routerLink: '/social',
            class: 'social',
            title: 'Social',
            bubblePosition: 10,
            deskPosition: 10,
        });

        this.iconList.push(<IIconItem>{
            routerLink: '/',
            class: 'home',
            title: 'Dock',
            bubblePosition: 0,
            deskPosition: 0,
        });

        this.iconList.push(<IIconItem>{
            routerLink: '/sms-calculator',
            class: 'calculator',
            title: 'Calculator',
            bubblePosition: 12,
            deskPosition: 12,
        });

    }

    /**
     * Social icons
     */

    public getSocialIcons() {
        return this.socialList;
    }

    public socialList: IIconItem[];

    private setSocialIconList() {
        this.socialList = [];

        this.socialList.push(<IIconItem>{
            routerLink: 'https://www.facebook.com/alarislabs/',
            class: 'fb',
            title: 'Facebook'
        });

        this.socialList.push(<IIconItem>{
            routerLink: 'https://www.linkedin.com/company/alaris-labs/',
            class: 'linked',
            title: 'LinkedIn'
        });

        this.socialList.push(<IIconItem>{
            routerLink: 'https://www.youtube.com/c/Alarislabs',
            class: 'youtube',
            title: 'Youtube'
        });

        this.socialList.push(<IIconItem>{
            routerLink: 'https://www.instagram.com/alarislabs_com/',
            class: 'inst',
            title: 'Instagram'
        });


        this.socialList.map(p => {
            p.x = 0;
            p.mode = 'social';
        })
    }
}

export interface IIconItem {
    routerLink: string,
    class: string,
    title: string,
    bubblePosition: number,
    deskPosition: number,
    x: number,
    y: number,
    state: string,
    mode: string,
    type: string,
    descr: string,
    account: string,
    link: string

    // routerLink: string,
    // y: number,
    // x: number,

    opacity: number,
    // class: string,
    // title: string,
    // bigIconSrc: string,
    // bigIconHoverSrc: string,
    // bigIconOnclickSrc: string,
    // dockIconSrc: string,
    // dockIconHoverSrc: string,
    // dockIconOnclickSrc: string,
    // state: string,
    // mode: string,
    // type: string,
    // descr: string
}


