import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    Renderer2,
    HostListener,
    EventEmitter
} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';

import 'rxjs/add/operator/filter';
import {animate, state, style, transition, trigger} from "@angular/animations";
// import {HotService} from "../services/hot.service";
import {IconsService, IIconItem} from "../services/icons.service";
import {StateService} from "../services/state.service";


@Component({
    selector: 'dock',
    templateUrl: './dock.component.html',
    styleUrls: [
        './dock.component.less', './dock.icons.less'],
    animations: [
        trigger("bubble", [
            state('init', style({
                opacity: '0',
            })),
            state('lens', style({
                opacity: '1',
            })),
            state('bubble', style({
                opacity: '1',
            })),
            state('dock', style({
                opacity: '1',
            })),
            transition('init => lens', [
              animate('2s ease-out')
            ]),
            transition('init => bubble', [
              animate('2s ease-out')
            ]),
            transition('lens => dock', [
              animate('1s ease-out')
            ]),
            transition('bubble => dock', [
              animate('2s ease-out')
            ])

        ])]

})
export class DockComponent implements OnInit, OnDestroy {

    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        private icons: IconsService,
        private state: StateService
    ) {
        this.initIconList();
        this.initDock();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.icons.setIcons();
        this.initIconList();
        this.initDock();
    }


    // @Input() animation: boolean;

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (event.url == '/') {
                    this.state.setDockMode('home')
                } else {
                    this.state.setDockMode('dock');
                    this.onShowDock('dock-hide');
                }
            }
        });

        this.state.dockModeEvent.subscribe( p => {
            this.dockMode = this.state.getDockMode();
        })
    }

    ngOnDestroy() {
    }

    ngAfterViewInit() {
    }

    public iconList;
    public dockMode: any;
    public dockShow: 'dock-show' | 'dock-hide';


    public mainSize: 'full' | 'medium' | 'small' | 'xs' | 'xxs';
    public dockSize: 'full' | 'medium' | 'small' | 'xs' | 'xxs';

    analyzeSize() {
        this.mainSize = (window.innerWidth > 1440) ? 'full' : (window.innerWidth > 1024)
            ? 'medium' : (window.innerWidth > 768) ? 'small' : (window.innerWidth > 540) ? 'xs' : 'xxs';
        this.state.setMainSize(this.mainSize);

        this.dockSize = (window.innerWidth > 1440) ? 'full' : (window.innerWidth > 1024)
            ? 'medium' : (window.innerWidth > 768) ? 'small' : (window.innerWidth > 540) ? 'xs' : 'xxs';
    }

    public miniDock = <any>{};
    public dockHeight = 120;

    setMiniDockParams() {
        this.miniDock = {};
        let iconSize = 60;

        let iconsInRow = (window.innerWidth > 540) ? 6 : (window.innerWidth > 420) ? 4 : Math.floor((window.innerWidth)/(iconSize+20));

        this.miniDock.width = window.innerWidth;
        this.miniDock.height = Math.ceil(this.iconList.length / iconsInRow) * (iconSize+60);
        this.dockHeight = this.miniDock.height;
    }

    /***************************************************************************************************
     *
     *    ICONS State Machine
     *
     *************************************************************************************************/


    initDock() {
        this.analyzeSize();
        this.setMiniDockParams();

        if (this.dockMode == 'home') {
            this.dockBottom = 0;
        } else {
            this.onShowDock('dock-hide');
        }
    }

    initIconList(){
        this.iconList = <any>[];
        let tmpList = <any>[];

        this.icons.getIcons().map(icon => {
            tmpList[icon.deskPosition]= icon; // sorting by desktopPosition
        });

        tmpList.map((icon) => {
            this.iconList.push(icon); //
        });
    }

    public dockBottom = 0;

    onShowDock(state?) {
        this.dockShow = state || (this.dockShow == 'dock-show') ? 'dock-hide' : 'dock-show';

        this.state.setDockShow(this.dockShow);

        switch (this.dockSize) {
            case 'full':
                this.dockBottom = 0;
                break;

            case 'medium':
                this.dockBottom = 0;
                break;

            case 'small':
                if (this.dockMode == 'home') break;
                this.dockBottom = (this.dockShow == 'dock-show') ? 0 : -this.dockHeight;
                break;

            case 'xs':
                if (this.dockMode == 'home') break;
                this.dockBottom = (this.dockShow == 'dock-show') ? 0 : -this.dockHeight;
                break;

            case 'xxs':
                if (this.dockMode == 'home') break;
                this.dockBottom = (this.dockShow == 'dock-show') ? 0 : -this.dockHeight;
                break;

            default:

                this.dockBottom = 0;
                break;

        }
    }


    onPush(ref, icon) {
        ref.target.className = ref.target.className + " push";
    }

    onMouseUp(ref, icon) {
        ref.target.className = ((icon.mode == 'social') ? 'social-icons-item ' : 'icon-item ') + icon.class;
    }

}



