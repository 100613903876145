import {EventEmitter} from "@angular/core";

export class StateService {

    constructor() {

    }

    // DOCK MODE
    dockModeEvent: EventEmitter<any> = new EventEmitter();
    public dockMode: string;

    public emitDockModeChangeEvent(mode) {
        this.dockModeEvent.emit(mode);
    }

    public getDockModeChangeEmitter() {
        return this.dockModeEvent;
    }

    public setDockMode(dockMode: 'home' | 'dock') {
        this.dockMode = dockMode;
        this.emitDockModeChangeEvent(dockMode);
    }

    public getDockMode() {
        return this.dockMode;
    }

    // MAIN SIZE
    mainSizeEvent: EventEmitter<any> = new EventEmitter();
    public mainSize = 'full';

    public emitMainSizeChangeEvent(size) {
        this.mainSizeEvent.emit(size);
    }

    public getMainSizeChangeEmitter() {
        return this.mainSizeEvent;
    }

    public setMainSize(size: any) {
        this.mainSize = size;
        this.emitMainSizeChangeEvent(size);
    }

    public getMainSize() {
        return this.mainSize;
    }


    /**
     *
     * State
     */
    public state: any;
    stateChange: EventEmitter<any> = new EventEmitter();

    public emitStateChangeEvent(state) {
        this.stateChange.emit(state);
    }

    public getStateChangeEmitter() {
        return this.stateChange;
    }

    public setState(state) {
        this.state = state;
        this.emitStateChangeEvent(state);
        return state;

    }

    public getState() {
        return this.state;
    }


    /**
     *
     *   DOCK SHOW
     */

    public dockShowEvent: EventEmitter<any> = new EventEmitter();
    public dockShow: string;

    public emitDockShowChangeEvent(dockShow) {
        this.dockShowEvent.emit(dockShow);
    }

    public getDockShowChangeEmitter() {
        return this.dockShowEvent;
    }

    public setDockShow(dockShow: 'dock-show' | 'dock-hide') {
        this.dockMode = dockShow;
        this.emitDockShowChangeEvent(dockShow);
    }

    public getDockShow() {
        return this.dockShow;
    }


}


