<div class="stretched device-sm">

  <div id=" wrapper" class="stretched clearfix" style="opacity: 1;">

    <header>
      <div class="logo">
        <a class="logo-icon" href="/" routerLink="/"></a>
        <a class="header-details" href="/sms-calculator" routerLink="/sms-calculator"> | {{ "SMSCalculator.Title" | translate }}</a>
      </div>
    </header>


    <!-- Content
      ============================================= -->
    <section id="content" style="margin-top: 60px;" #SMSTop>

      <div class="content-wrap">
        <div class="topmargin sms-header-bg">
          <div class="container clearfix">

            <div class="heading-block center topmargin noborder">
              <h2 class="text-w">{{'SMSCalculator.Title' | translate}}</h2>
              <span class="text-w">{{'SMSCalculator.Descr' | translate}}</span>
            </div>

          </div>
        </div>

        <div class="section nomargin">
          <div class="container clearfix">
            <h4>{{'SMSCalculator.EnterSMSContent' | translate}}</h4>
            <div class="col_half">
              <textarea class="sms-input-field" [ngModel]="smsText" (ngModelChange)="setSmsText($event)"></textarea>
            </div>

            <div class="col_half col_last sms-counter-results-wrapper">

              <div class="sms-counter-results-wrapper-item">
                <div>{{'SMSCalculator.Encoding' | translate}}</div>
                <div>{{smsEncoded.encodingType}}</div>
              </div>

              <div class="sms-counter-results-wrapper-item">
                <div>{{'SMSCalculator.NumberSMSMessages' | translate}}</div>
                <div>{{smsEncoded.messages}}</div>
              </div>

              <div class="sms-counter-results-wrapper-item">
                <div>{{'SMSCalculator.CharactersUsed' | translate}}</div>
                <div>{{smsEncoded.length}}</div>
              </div>

              <div class="sms-counter-results-wrapper-item">
                <div>{{'SMSCalculator.CharactersInMessage' | translate}}</div>
                <div>{{smsEncoded.per_message}}</div>
              </div>

            </div>

          </div>
        </div>


        <div class="section section2 nomargin">
          <div class="container clearfix">
            <div class="col_half">
              <h4>{{'SMSCalculator.HowSMSLooks' | translate}}</h4>
              <p>{{'SMSCalculator.HowSMSLooksDescr' | translate}}</p>
            </div>

            <div class="col_half col_last">

              <div class="sms-symbols-container">
                <div *ngFor="let message of smsEncoded.content; let i = index;" class="sms-message">
                  <span *ngIf="smsEncoded.messages > 1"> 0x050x000x030x0a0x030x0{{i}}</span>
                  <span *ngFor="let item of message" [class]="(item != 'ESC') ? 'sms-symbol' : ''">
                    {{item}}
                  </span>
                </div>
              </div>
            </div>


          </div>
        </div>


        <div class="container clearfix">

          <div class="col_half">

            <div class="phone-wrapper">
              <div class="messages-container">


                  <div *ngFor="let message of smsEncoded.smsMessages" class="sms-message" class="message-item">
                    {{message}}
                  </div>


              </div>
            </div>

          </div>

          <div class="col_half col_last">
            <h2 class="topmargin topmargin-lg">{{'SMSCalculator.HowSMSLooksOnDevice' | translate}}</h2>
            <div class="search-cats"></div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!-- Go To Top -->

  <!-- Go To Top -->
  <div class ="icon-angle-up goTopBtn"
       [class.display-block]="!(dockShow)"
       [class.display-none]="dockShow"
       (click)="scrollToEl(SMSTop);">
  </div>

<!--  <app-sms-controller></app-sms-controller>-->

</div>
