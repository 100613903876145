<div class="stretched device-sm">

    <div id=" wrapper" class="stretched clearfix" style="opacity: 1;">

        <header>
            <div class="logo">
                <a class="logo-icon" href="/" routerLink="/"></a>
                <a class="header-details" href="/privacy-notice" routerLink="/privacy-notice"> | {{ "PrivacyNotes.Title" | translate }}</a>
            </div>
        </header>


        <!-- Content
          ============================================= -->
        <section id="content" style="margin-top: 60px;" #PrivacyTop>

            <div class="privacy-wrap"></div>

            <div class="content-wrap">
                <div class="container clearfix">
                    <app-privacy-notice-content></app-privacy-notice-content>
                </div>
            </div>
        </section>
    </div>

    <!-- Go To Top -->
    <div class ="icon-angle-up goTopBtn"
         [class.display-block]="!(dockShow)"
         [class.display-none]="dockShow"
         (click)="scrollToEl(PrivacyTop);">
    </div>

</div>