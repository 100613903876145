<!--<div style="position: fixed; width: 100vw; z-index:9999999;">-->
  <!--<button (click)="switchLanguage('en')">en</button>-->
  <!--<button (click)="switchLanguage('ru')">ru</button>-->
  <!--<button (click)="switchLanguage('sp')">sp</button>-->
<!--</div>-->


<lang-selector style="opacity: 0"></lang-selector>
<router-outlet></router-outlet>


<dock></dock>

<order-modal></order-modal>
<alert style="z-index: 99999999999999999999;"></alert>
