import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CustomerSurveyComponent} from "./tools/customer-survey/customer-survey.component";
import {ErrorComponent} from "./tools/error/error.component";
import {PrivacyNoticeComponent} from "./privacy-notice/privacy-notice.component";
import {SmsCalculatorComponent} from "./sms-calculator/sms-calculator.component";
import {sectionsMetadata} from "./tools/sections.metadata";
import {InformationSecurityComponent} from "./information-security/information-security.component";

const routes: Routes = [
    {
        path: "",
        data: sectionsMetadata.start,
        loadChildren: "../app/start/start.module#StartModule"
    },

    {
        path: "about",
        data: sectionsMetadata.about,
        loadChildren: "../app/about/about.module#AboutModule"
    },
    {
        path: "clients",
        data: sectionsMetadata.clients,
        loadChildren: "../app/clients/clients.module#ClientsModule"
    },
    {
        path: "demo",
        data: sectionsMetadata.demo,
        pathMatch: 'prefix',
        loadChildren: "../app/demo/demo.module#DemoModule"
    },

    {
        path: 'messaging',
        data: sectionsMetadata.messaging,
        loadChildren: "../app/messaging/messaging.module#MessagingModule",
    },
    {
        path: 'voice',
        data: sectionsMetadata.voice,
        loadChildren: "../app/voice/voice.module#VoiceModule",
    },
    {
        path: 'charity',
        loadChildren: "../app/charity/charity.module#CharityModule",
    },
    {
        path: "exhibition",
        data: sectionsMetadata.expo,
        loadChildren: "../app/exhibition/exhibition.module#ExhibitionModule"
    },
    {
        path: "news",
        data: sectionsMetadata.news,
        loadChildren: "../app/news/news.module#NewsModule"
    },

    {
        path: "blog",
        data: sectionsMetadata.blog,
        loadChildren: "../app/blog/blog.module#BlogModule"
    },

    {
        path: "account",
        data: sectionsMetadata.start,
        loadChildren: "../app/login/login.module#LoginModule"
    },

    {
        path: "faq",
        data: sectionsMetadata.faq,
        loadChildren: "../app/faq/faq.module#FaqModule"
    },

    {
        path: "contacts",
        data: sectionsMetadata.contacts,
        loadChildren: "../app/contacts/contacts.module#ContactsModule"
    },

    {
        path: "social",
        data: sectionsMetadata.social,
        loadChildren: "../app/social/social.module#SocialModule"
    },

    {
        path: "customer-survey",
        data: sectionsMetadata.start,
        component: CustomerSurveyComponent
    },
    {
        path: "privacy-notice",
        data: sectionsMetadata.privacyNotice,
        component: PrivacyNoticeComponent
    },
    {
        path: "information-security",
        data: sectionsMetadata.informationSecurity,
        component: InformationSecurityComponent
    },
    {
        path: "sms-calculator",
        data: sectionsMetadata.smsCalculator,
        component: SmsCalculatorComponent
    },

    // // otherwise redirect to home
    // {path: '**', redirectTo: ''}

    //Wild Card Route
    { path: '**', pathMatch   : 'full', component: ErrorComponent},

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {
}
