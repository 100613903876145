import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SEOService} from "../services/seo.service";
import {StateService} from "../services/state.service";
import {ScrollToService} from "ng2-scroll-to-el";
import {ISMSCounter, SmsCalculatorService} from "./sms-calculator.service";
import {Subject} from "rxjs";
import { filter, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sms-calculator',
  templateUrl: './sms-calculator.component.html',
  styleUrls: [
    './sms-calculator.component.less',
    '../tools/header.less']
})
export class SmsCalculatorComponent implements OnInit {

  constructor(
      public activatedRoute: ActivatedRoute,
      private SEOService: SEOService,
      private state: StateService,
      private scrollService: ScrollToService,
      private smsCalculatorService: SmsCalculatorService
  ) {

    this.smsEncoded = smsCalculatorService.getSmsCounter();

  }

  private ngUnsubscribe = new Subject();

  ngOnInit() {
    // set meta tags
    const { meta } = this.activatedRoute.snapshot.data;
    this.SEOService.updateTitle(meta.title);
    this.SEOService.updateDescription(meta.description);

    this.state.getDockShowChangeEmitter()
    .pipe(
        startWith([]),
        takeUntil(this.ngUnsubscribe)
    )
    .subscribe(dockShow => {
      this.dockShow = (dockShow == 'dock-show');
    })

    this.smsCalculatorService.getSmsCounterChangeEmitter()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(smsEncoded => this.smsEncoded = smsEncoded);
  }

  ngOnDestroy() {
    this.smsCalculatorService.setSmsText('');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public dockShow = false;

  scrollToEl(element) {
    this.scrollService.scrollTo(element, 500, -60);
  }

  public smsText: string;
  public smsEncoded: ISMSCounter;

  setSmsText(text){
    this.smsText = text;
    this.smsCalculatorService.setSmsText(text);
  }

}
