import {Component, ElementRef, EventEmitter, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {AlertService, IAlert} from "./alert.service";


@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.less'],
  animations: [
    trigger("Show", [
      state('hide', style({
        opacity: '0',
        top: '-30em',
      })),
      state('show', style({
        opacity: '1',
        top: '5em',
      })),
      transition('hide => show', [
        animate('0.3s ease-out')
      ]),
      transition('show => hide', [
        animate('0.3s ease-in')
      ]),
    ])
  ]

})
export class AlertComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    private service: AlertService
    ) {

    this.state = this.service.getState();

  }

  public state: IAlert;
  private timer;


  ngAfterViewInit(){

  }


  /**
   *  SUBSCRIBERS
   */

  ngOnInit() {
    this.stateSubscription = this.service.getStateChangeEmitter()
      .subscribe(state => this.changeState(state));
  }

  ngOnDestroy() {
    this.stateSubscription.unsubscribe();
    if (this.timer) clearInterval(this.timer);
  }

  private stateSubscription: any;

  changeState(state: IAlert) {
    this.state = state;
    if (this.timer) clearInterval(this.timer);
    if(state.state == "show"){
      let dly = 4000; //this.state.delay ||`
      this.timer = setInterval(() => this.onCloseBtn(), dly);
    }
  }

  /**
   *  CONTROLLER
   */

  public onCloseBtn(){
    this.service.setState(<IAlert>{state: "hide"})
  }

  public onActionBtn(){
    eval(this.state.action);
    this.service.setState(<IAlert>{state: "hide"})
  }

}


